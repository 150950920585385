// ApexLineChart.js

import React from "react";
import Chart from "react-apexcharts";

const ApexLineChart = ({ xData, yData, name, color }) => {
  const options = {
    chart: {
      type: "area",
      foreColor: "#404040",
      //   zoom: {
      //     type: "x",
      //     enabled: true,
      //   },
      toolbar: {
        show: false, // Disable the toolbar
      },
    },
    colors: [color || "blue"],
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 1,
        gradientToColors: ["#fff"],
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0.0,
        stops: [0, 90, 100],
      },
    },
    xaxis: {
      categories: xData,
      //   categories: [
      //     "Jan",
      //     "Feb",
      //     "Mar",
      //     "Apr",
      //     "May",
      //     "Jun",
      //     "Jul",
      //     "Aug",
      //     "Sep",
      //     "Oct",
      //     "Nov",
      //     "Dec",
      //   ],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    yaxis: {
      //   min: 0,
      //   max: 140,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    grid: {
      show: false, // Optionally remove the grid as well
    },
    tooltip: {
      theme: "dark",
    },
    dataLabels: {
      enabled: false,
    },
  };

  const series = [
    {
      name: name, //"Sales",
      data: yData,
    },
  ];

  return (
    <div>
      <Chart options={options} series={series} type="area" height="200" />
    </div>
  );
};

export default ApexLineChart;
