import React, { useEffect, useState } from "react";
import InstitutionsListFilters from "../components/institutions/InstitutionsListFilters";
import { BASE_INSTITUTION_API_URL } from "../data_utils/base_api_urls";
import GenericTable from "../components/utils/GenericTable";
import MateriaTable from "../components/utils/MaterialTable";
import WhiteCard from "../components/utils/WhiteCard";
import ApexPieChart from "../components/charts/ApexPieChart";
import Axios from "../services/axios.custom";

const InstitutionsView = () => {
  const [search, setSearch] = useState("");
  const [url, setUrl] = useState(BASE_INSTITUTION_API_URL);

  const [countryGroups, setCountryGroups] = useState([]);
  useEffect(() => {
    const fetchCountryGroups = async () => {
      try {
        const { data } = await Axios.get(`${url}&group_by=country_code`);
        setCountryGroups(data["group_by"]);
      } catch (e) {
        console.log("error fetching group by published year for works");
      }
    };
    fetchCountryGroups();
  }, [url]);

  const [countinentGroups, setContinentGroups] = useState([]);
  useEffect(() => {
    const fetchContinentGroups = async () => {
      try {
        const { data } = await Axios.get(`${url}&group_by=continent`);
        setContinentGroups(data["group_by"]);
      } catch (e) {
        console.log("error fetching group by published year for works");
      }
    };
    fetchContinentGroups();
  }, [url]);

  useEffect(() => {
    setUrl(`${BASE_INSTITUTION_API_URL}search=${search}`);
  }, [search]);
  return (
    <div className="container-fluid mt-2">
      <div className="row">
        <div className="col-12">
          <WhiteCard title="اعمال فیلتر">
            <InstitutionsListFilters onSearch={setSearch} />
          </WhiteCard>
          <div className="row">
            <div className="col-4">
              <WhiteCard
                title={"مراکز تحقیقاتی"}
                subtitle={"تنوع مراکز تحقیقاتی بر مبنای کشور"}
              >
                {countryGroups.length && (
                  <ApexPieChart
                    labels={countryGroups.map(
                      (group) => group.key_display_name
                    )}
                    values={countryGroups.map((group) => group.count)}
                  />
                )}
              </WhiteCard>
              <WhiteCard
                title={"مراکز تحقیقاتی"}
                subtitle={"تنوع مراکز تحقیقاتی بر مبنای قاره"}
              >
                {countinentGroups.length && (
                  <ApexPieChart
                    labels={countinentGroups.map(
                      (group) => group.key_display_name
                    )}
                    values={countinentGroups.map((group) => group.count)}
                  />
                )}
              </WhiteCard>
            </div>
            <div className="col-8">
              <WhiteCard title={"جدول مراکز تحقیقاتی"}>
                <MateriaTable
                  fetch_url={url}
                  fields={["display_name", "cited_by_count", "works_count"]}
                  titles={["عنوان", "تعداد ارجاعات", "تعداد مقالات"]}
                  redirect_url={"/institutions"}
                />
              </WhiteCard>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstitutionsView;
