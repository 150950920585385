import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { englishNumberToPersian } from "../data_utils/language_converter";
import LoadingIndicator from "../components/utils/LoadingIndicator";
import ApexLineChart from "../components/charts/ApexLineChart";
import { NAMED_COLORS } from "../data_utils/colors";
import Axios from "../services/axios.custom";
import PublisherStatsCard from "../components/publishers/PublisherStatsCard";

const SinglePublisherView = () => {
  const [publisher, setPublisher] = useState({});
  const params = useParams();

  useEffect(() => {
    const fetchPublisher = async () => {
      try {
        const { data } = await Axios.get(`/api/publishers/${params.id}`);
        // dispatch(setSingleAuthor(data));
        setPublisher(data);
      } catch (e) {
        console.log(e);
      }
    };
    fetchPublisher();
  }, []);

  const renderStats = () => {
    const stats = [
      {
        name: "h_index",
        display_name: "H Index",
        value: publisher.summary_stats.h_index,
        color: NAMED_COLORS.LIGHT_PINK,
      },
      {
        name: "i10_index",
        display_name: "I10 Index",
        value: publisher.summary_stats.i10_index,
        color: NAMED_COLORS.LIGHT_GREEN,
      },
      {
        name: "total_citations",
        display_name: "کل ارجاعات",
        value: publisher.cited_by_count,
        color: NAMED_COLORS.LIGHT_BLUE,
      },
      {
        name: "Count of Works",
        display_name: "تعداد مقالات",
        value: publisher.works_count,
        color: NAMED_COLORS.LIGHT_PURPLE,
      },
    ];
    stats.forEach((stat) => {
      stat.value = englishNumberToPersian(stat.value);
    });
    return <PublisherStatsCard stats={stats} />;
  };
  let chartData = [];
  if (publisher.counts_by_year) {
    chartData = publisher.counts_by_year;
    chartData.sort((a, b) => (a.year > b.year ? 1 : -1));
  }
  let xData = chartData.map((x) => x.year);
  let worksCount = chartData.map((x) => x.works_count);
  let citations = chartData.map((x) => x.cited_by_count);
  return (
    <div className="container-fluid my-2">
      <div className="row">
        <div className="col-12">
          {Object.keys(publisher).length ? (
            <>
              <div className="white_card rounded-bg">
                <div className="white_card_header">
                  <div className="author-info">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="main-title">
                        <h3 className="m-0">{publisher.display_name}</h3>
                        <span>{publisher.description}</span>
                      </div>
                      <h1 className="text-title"></h1>
                      <img
                        className="inline-flag"
                        src={publisher.image_thumbnail_url}
                        alt="country flag"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex mt-2">
                <div className="d-flex flex-column flex-grow-1">
                  <div className="white_card rounded-bg flex-grow-1 me-2 ">
                    <div className="white_card_header rtl">
                      <div className="main-title">
                        <h3 className="m-0">تعداد پژوهش ها در طول زمان</h3>
                        <span>از سال ۲۰۱۲ الی ۲۰۲۴</span>
                      </div>
                    </div>
                    <div className="author-charts" style={{ height: "200px" }}>
                      <ApexLineChart
                        xData={xData}
                        yData={worksCount}
                        name={"تعداد پژوهش ها"}
                      />
                    </div>
                  </div>

                  <div className="white_card rounded-bg flex-grow-1 me-2 mt-2">
                    <div className="white_card_header rtl">
                      <div className="main-title">
                        <h3 className="m-0">تعداد ارجاعات در طول زمان</h3>
                        <span>از سال ۲۰۱۲ الی ۲۰۲۴</span>
                      </div>
                    </div>
                    <div className="author-charts" style={{ height: "200px" }}>
                      <ApexLineChart
                        xData={xData}
                        yData={citations}
                        name={"تعداد ارجاعات"}
                        color={"orangered"}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ minWidth: "450px" }}>{renderStats()}</div>
              </div>
            </>
          ) : (
            <LoadingIndicator />
          )}
        </div>
      </div>
    </div>
  );
};

export default SinglePublisherView;
