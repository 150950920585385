import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import MateriaTable from "../components/utils/MaterialTable";
import WhiteCard from "../components/utils/WhiteCard";
import ApexTreeMapChart from "../components/charts/ApexTreeMapChart";
import Axios from "../services/axios.custom";
import { BASE_COUNTRIES_API_URL } from "../data_utils/base_api_urls";

const CountriesView = () => {
  const [countryName, setCountryName] = useState("");
  const [countryData, setCountryData] = useState({
    values: [],
    labels: [],
  });
  useEffect(() => {
    const fetchCountryData = async () => {
      try {
        const { data } = await Axios.get(
          `${BASE_COUNTRIES_API_URL}select=display_name,works_count&per-page=300`
        );
        const labels = data["results"].map((res) => res.display_name);
        const values = data["results"].map((res) => res.works_count);
        setCountryData({
          values,
          labels,
        });
      } catch (e) {}
    };
    fetchCountryData();
  }, []);
  return (
    <div className="view container-fluid">
      <div className="row">
        <div className="col-12 rtl">
          <WhiteCard title={"اعمال فیلتر"}>
            <div className="text-center">
              <TextField
                dir="rtl"
                id="outlined-basic"
                label="عنوان کشور"
                variant="outlined"
                value={countryName}
                onChange={(e) => setCountryName(e.target.value)}
              />
            </div>
          </WhiteCard>
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <WhiteCard
            title="نقشه درختی کشورها"
            subtitle="برمبنای تعداد پژوهش های تولیدی"
          >
            <ApexTreeMapChart
              values={countryData.values}
              labels={countryData.labels}
            />
          </WhiteCard>
        </div>
        <div className="col-8">
          <WhiteCard
            title={"جدول کشورها"}
            subtitle={"مرتب شده بر اساس تعداد مقالات"}
          >
            <MateriaTable
              fetch_url={`/api/countries?search=${countryName}`}
              fields={[
                "display_name",
                "continent.display_name",
                "works_count",
                "cited_by_count",
              ]}
              classNames="english"
              titles={["عنوان", "قاره", "تعداد مقالات", "تعداد ارجاعات"]}
              redirect_url={"/countries"}
            />
          </WhiteCard>
        </div>
      </div>
    </div>
  );
};

export default CountriesView;
