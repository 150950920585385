import React, { useEffect, useState } from "react";
import WorksListFilters from "../components/works/WorksListFilters";
import GenericTable from "../components/utils/GenericTable";
import MateriaTable from "../components/utils/MaterialTable";
import ApexPieChart from "../components/charts/ApexPieChart";
import WhiteCard from "../components/utils/WhiteCard";
import Axios from "../services/axios.custom";

const BASE_WORK_API_URL = "/api/works?";
const WorksView = () => {
  const [search, setSearch] = useState("");
  const [url, setUrl] = useState(BASE_WORK_API_URL);

  const [typeGroups, setTypeGroups] = useState([]);
  const [publishedYearGroups, setPublishedYearGroups] = useState([]);

  useEffect(() => {
    const fetchPublishedYearGroups = async () => {
      try {
        const { data } = await Axios.get(`${url}&group_by=publication_year`);
        setPublishedYearGroups(data["group_by"]);
      } catch (e) {
        console.log("error fetching group by published year for works");
      }
    };
    fetchPublishedYearGroups();
  }, [url]);
  useEffect(() => {
    const fetchTypeGroups = async () => {
      try {
        const { data } = await Axios.get(`${url}&group_by=type`);
        setTypeGroups(data["group_by"]);
      } catch (e) {
        console.log("error fetching group by for works");
      }
    };
    fetchTypeGroups();
  }, [url]);

  useEffect(() => {
    setUrl(`${BASE_WORK_API_URL}search=${search}`);
  }, [search]);
  // useEffect(() => {

  // }, [url])
  return (
    <div className="container-fluid mt-2">
      <div className="row">
        <div className="col-12">
          <WhiteCard title={"اعمال فیلتر"}>
            <WorksListFilters onSearch={setSearch} />
          </WhiteCard>
          <div className="row">
            <div className="col-4">
              <WhiteCard
                title={"تنوع مقالات"}
                subtitle={"گروه بندی پژوهش ها بر اساس نوع"}
              >
                {typeGroups.length && (
                  <ApexPieChart
                    labels={typeGroups.map((group) => group.key)}
                    values={typeGroups.map((group) => group.count)}
                  />
                )}
              </WhiteCard>
              <WhiteCard
                title={"تنوع مقالات"}
                subtitle={"گروه بندی پژوهش ها بر اساس سال انتشار"}
              >
                {publishedYearGroups.length && (
                  <ApexPieChart
                    labels={publishedYearGroups.map((group) => group.key)}
                    values={publishedYearGroups.map((group) => group.count)}
                  />
                )}
              </WhiteCard>
            </div>
            <div className="col-8">
              <WhiteCard title={"فهرست پژوهش ها"}>
                <MateriaTable
                  fetch_url={url}
                  fields={[
                    "display_name",
                    "cited_by_count",
                    "publication_year",
                  ]}
                  titles={["عنوان", "تعداد ارجاعات", "سال انتشار"]}
                  redirect_url={"/works"}
                />
              </WhiteCard>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorksView;
