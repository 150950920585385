import React, { useEffect, useState } from "react";
import StaticMaterialTable from "../utils/StaticMaterialTable";
import WhiteCard from "../utils/WhiteCard";
import Axios from "../../services/axios.custom";
import { CountryCodeToPersianName } from "../../data_utils/country_continent";

const CoauthoredInstitutions = ({ institutionId }) => {
  const [coauthoredInstitutions, setCoauthoredInstitutions] = useState([]);
  useEffect(() => {
    const fetchCoauthoredInstitutions = async () => {
      try {
        const { data } = await Axios.get(
          `/v2/api/institution_most_common_coauthorship/${institutionId}`
        );
        setCoauthoredInstitutions(data);
      } catch (e) {
        console.log("failed fetching institution coauthors");
      }
    };
    fetchCoauthoredInstitutions();
  }, []);
  return (
    <WhiteCard
      title="موسساتی با بیشترین پژوهش مشترک"
      subtitle="مرتب شده بر اساس تعداد پژوهش مشترک"
    >
      <StaticMaterialTable
        data={coauthoredInstitutions}
        column_fields={["institution_name", "works_count"]}
        column_names={["نام موسسه", "تعداد مقالات مشترک"]}
        id_field={"institution_id"}
        redirect_field={"institution_name"}
        redirect_url={"/institutions"}
        expand_key={null}
      />
    </WhiteCard>
  );
};

export default CoauthoredInstitutions;
