import React from "react";
import { Chip } from "@mui/material";
import {
    MUI_NEW_BG_COLORS,
    MUI_NEW_FONT_COLORS,
} from "../../data_utils/colors";

const CustomChip = ({ label, className, color }) => {
    return (
        <Chip
            label={label}
            style={{
                borderRadius: "5px",
                backgroundColor: MUI_NEW_BG_COLORS[color],
                color: MUI_NEW_FONT_COLORS[color],
            }}
            className={className}
        />
    );
};

export default CustomChip;
