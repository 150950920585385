import React, { useState } from "react";
import WhiteCard from "../utils/WhiteCard";

const AuthorsListFilters = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const onButtonClicked = () => {
    onSearch(searchTerm);
  };

  return (
    <WhiteCard className="" title={"اعمال فیلتر"}>
      <div className="author-filters">
        <div className="rtl d-flex align-items-center justify-content-center">
          <input
            type="text"
            className="form-control search-input mx-2"
            placeholder="نام نویسنده"
            aria-label="نام نویسنده"
            aria-describedby="basic-addon2"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button
            className="btn btn-outline-success"
            type="button"
            onClick={onButtonClicked}
          >
            جستجو
          </button>
          {/* <div className="input-group-append"></div> */}
        </div>
      </div>
    </WhiteCard>
  );
};

export default AuthorsListFilters;
