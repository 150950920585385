import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
} from "@mui/material";
import Axios from "../../services/axios.custom";
import LoadingIndicator from "./LoadingIndicator";
import { NavLink } from "react-router-dom";

const MateriaTable = ({ fields, fetch_url, redirect_url, titles }) => {
  const PER_PAGE = 10;
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [results, setResults] = useState([]);
  const [total, setTotal] = useState(0);

  const fetchEntities = async () => {
    setResults([]);
    setLoading(true);
    let selectFields = ["id"];
    for (let field of fields) {
      if (field.indexOf(".") === -1) {
        selectFields.push(field);
      } else {
        selectFields.push(field.split(".")[0]);
      }
    }
    try {
      const { data } = await Axios.get(
        `${fetch_url}&per-page=${PER_PAGE}&page=${page}&select=${selectFields.join(
          ","
        )}`
      );
      setTotal(data.meta.count);
      setResults(data.results);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchEntities();
  }, [page, fetch_url]);
  useEffect(() => {
    setPage(1);
  }, [fetch_url]);
  const onPageChanged = (e, newPage) => {
    setPage(newPage);
  };
  const onClick = (entity) => {};
  return (
    <>
      {loading ? (
        <LoadingIndicator />
      ) : total ? (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {titles.map((title) => (
                    <TableCell>{title}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {results.map((row) => {
                  let redirection_url;
                  if (row.id) {
                    let splits = row.id.split("/");
                    redirection_url =
                      redirect_url + "/" + splits[splits.length - 1];
                  } else {
                    redirection_url = redirect_url;
                  }
                  return (
                    <TableRow key={row.id}>
                      {/* <TableCell key={fields[0]}> */}
                      <TableCell>
                        <NavLink
                          onClick={() => onClick(row)}
                          className="clickable-link"
                          dangerouslySetInnerHTML={{
                            __html: row[fields[0]],
                          }}
                          to={`${redirection_url}`}
                        ></NavLink>
                      </TableCell>
                      {fields.slice(1).map((field) => {
                        let separated = field.split(".");
                        let cur = row;
                        for (let sep of separated) {
                          cur = cur[sep];
                        }
                        return (
                          <TableCell
                            dangerouslySetInnerHTML={{
                              __html: cur,
                            }}
                            // key={field}
                          ></TableCell>
                        );
                      })}
                    </TableRow>
                    // <TableRow key={row.id}>
                    // <TableCell>{row.id}</TableCell>
                    // <TableCell>{row.name}</TableCell>
                    // <TableCell>{row.age}</TableCell>
                    // </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="d-flex justify-content-center mt-3">
            <Pagination
              count={Math.ceil(total / PER_PAGE)}
              showFirstButton
              showLastButton
              onChange={onPageChanged}
              page={page}
            />
          </div>
        </>
      ) : (
        <div className="no-data-banner">
          متاسفانه اطلاعاتی برای نمایش وجود ندارد.
        </div>
      )}
    </>
  );
};

export default MateriaTable;
