const ENGLISH_PERSIAN_CONVERTION = {
    0: "۰",
    1: "۱",
    2: "۲",
    3: "۳",
    4: "۴",
    5: "۵",
    6: "۶",
    7: "۷",
    8: "۸",
    9: "۹",
};
export const englishNumberToPersian = (number) => {
    if (number === undefined)
        number = 0
    if (number === null) 
        number = 0
    let res = "";
    for (let ch of number.toString()) {
        if (ch in ENGLISH_PERSIAN_CONVERTION) {
            res += ENGLISH_PERSIAN_CONVERTION[ch];
        } else {
            res += ch;
        }
    }
    return res;
};
