import React from "react";
import "./WorkStatsCard.css";

const WorkStatsCard = ({ stats }) => {
  return (
    <div>
      <div
        className="white_card card_height_100 mb_30 social_media_card rtl"
        style={{
          backgroundImage: "url(/shape_2.svg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
        }}
      >
        <div className="white_card_header">
          <div className="main-title">
            <h3 className="m-0">آماره های پژوهش</h3>
            <span>عمق تاثیر آکادمیک پژوهش</span>
          </div>
        </div>
        <div className="media_thumb ml_25 ltr">
          <img src="/media.svg" alt="" />
        </div>
        <div className="media_card_body">
          <div className="media_card_list">
            {stats.map((stat) => (
              <div className="single_media_card">
                <span>{stat.display_name}</span>
                <h3>{stat.value}</h3>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkStatsCard;
