export const NAMED_COLORS = {
    LIGHT_PINK: "#FFD1DC",
    LIGHT_BLUE: "#ADD8E6",
    LIGHT_GREEN: "#C9FFC2",
    LIGHT_PURPLE: "#E0BBE4",
    LIGHT_YELLOW: "#FFFACD",
    LIGHT_PEACH: "#FFE5B4",
    LIGHT_MINT: "#98FF98",
    LIGHT_LAVENDER: "#FBAED2",
};
export const COLOR_LIST = Object.values(NAMED_COLORS);

export const MUI_NEW_BG_COLORS = {
    success: "#A0F5D1",
    error: "#E8CFCE",
    info: "#DDE1FD",
    warning: "#E8D188",
    primary: "#DACDEE",
};
export const MUI_NEW_FONT_COLORS = {
    success: "#0F231A",
    error: "#410E0B",
    info: "#05164C",
    warning: "#221B04",
    primary: "#251E5D",
};
