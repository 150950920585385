import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import { NavLink, redirect } from "react-router-dom";
import { Pagination } from "@mui/material";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function Row({
  row,
  expand_key,
  id_field,
  column_fields,
  redirect_field,
  redirect_url,
}) {
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        {expand_key && (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
            </IconButton>
          </TableCell>
        )}
        {/* <TableCell component="th" scope="row">
          {row.score}
        </TableCell> */}
        {column_fields.map((field) => (
          <TableCell align="left">
            {field === redirect_field ? (
              <NavLink
                className="clickable-link"
                to={`${redirect_url}/${row[id_field]}`}
              >
                {row[field]}
              </NavLink>
            ) : (
              row[field]
            )}
          </TableCell>
        ))}
      </TableRow>
      {expand_key && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                {/* <Typography variant="h6" gutterBottom component="div">
                  Abstract:
                </Typography> */}
                <Typography variant="caption">{row[expand_key]}</Typography>
                {/* <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">Total price ($)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      <TableCell component="th" scope="row">
                        {historyRow.date}
                      </TableCell>
                      <TableCell>{historyRow.customerId}</TableCell>
                      <TableCell align="right">{historyRow.amount}</TableCell>
                      <TableCell align="right">
                        {Math.round(historyRow.amount * row.price * 100) / 100}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table> */}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const StaticMaterialTable = ({
  data,
  column_fields,
  expand_key,
  column_names,
  id_field,
  redirect_field,
  redirect_url,
  perPage = 10,
}) => {
  const [page, setPage] = React.useState(1);
  const [currentData, setCurrentData] = React.useState([]);
  const [sortBy, setSortBy] = React.useState(null);

  const getPaginatedData = (data) => {
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    return data.slice(startIndex, endIndex);
  };
  const onColumnClicked = (column_name) => {
    const index = column_names.indexOf(column_name);
    const column_field = column_fields[index];
    if (sortBy && sortBy.slice(1) === column_field) {
      setSortBy(`${sortBy[0] === "+" ? "-" : "+"}${sortBy.slice(1)}`);
    } else {
      setSortBy(`+${column_field}`);
    }
  };

  const onPageChanged = (e, newPage) => {
    setPage(newPage);
  };

  React.useEffect(() => {
    let newData = data;
    if (sortBy) {
      let key = sortBy.slice(1);
      if (sortBy[0] === "+") {
        newData = [...data].sort((a, b) => (a[key] < b[key] ? 1 : -1));
      } else {
        newData = [...data].sort((a, b) => (a[key] < b[key] ? -1 : 1));
      }
    }
    const paginationData = getPaginatedData(newData);
    setCurrentData(paginationData);
  }, [page, data, sortBy]);
  return data.length ? (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {expand_key && <TableCell />}
              {column_names.map((name) => (
                <TableCell
                  onClick={() => onColumnClicked(name)}
                  className="cursor-pointer"
                  key={name}
                >
                  {name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentData.map((row) => (
              <Row
                key={row[id_field]}
                row={row}
                column_fields={column_fields}
                expand_key={expand_key}
                id_field={id_field}
                redirect_field={redirect_field}
                redirect_url={redirect_url}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="d-flex justify-content-center mt-3">
        <Pagination
          count={Math.ceil(data.length / perPage)}
          showFirstButton
          showLastButton
          onChange={onPageChanged}
          page={page}
        />
      </div>
    </>
  ) : (
    <div className="rtl text-center">
      هیچ مطلبی برای نمایش در جدول وجود ندارد.
    </div>
  );
};
export default StaticMaterialTable;
