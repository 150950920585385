import React, { useEffect, useState } from "react";
import { BASE_SOURCES_API_URL } from "../data_utils/base_api_urls";
import Axios from "../services/axios.custom";
import { convertCountryCodeToContinent } from "../data_utils/country_continent";
import ApexPieChart from "../components/charts/ApexPieChart";
import WhiteCard from "../components/utils/WhiteCard";
import MateriaTable from "../components/utils/MaterialTable";
import SourceListFilters from "../components/sources/SourceListFilters";

const SourcesView = () => {
  const [search, setSearch] = useState("");
  const [url, setUrl] = useState(BASE_SOURCES_API_URL);
  useEffect(() => {
    setUrl(`${BASE_SOURCES_API_URL}search=${search}`);
  }, [search]);

  const [countryGroups, setCountryGroups] = useState({
    values: [],
    labels: [],
  });
  const [continentGroups, setContinentGroups] = useState({
    values: [],
    labels: [],
  });
  const [typeGroups, setTypeGroups] = useState({
    values: [],
    labels: [],
  });

  useEffect(() => {
    const fetchCountryGroups = async () => {
      try {
        const { data } = await Axios.get(`${url}&group_by=country_code`);
        const groupByValues = data["group_by"].filter(
          (val) => val.key_display_name !== "unknown"
        );
        setCountryGroups({
          labels: groupByValues.map((val) => val.key_display_name),
          values: groupByValues.map((val) => val.count),
        });

        let continentLabels = [];
        let continentValues = [];
        let continentCount = {};
        for (let country of groupByValues) {
          const cont = convertCountryCodeToContinent(country.key);
          if (cont in continentCount) {
            continentCount[cont] += country.count;
          } else {
            continentCount[cont] = country.count;
          }
        }
        for (let cont in continentCount) {
          continentLabels.push(cont);
          continentValues.push(continentCount[cont]);
        }
        setContinentGroups({
          labels: continentLabels,
          values: continentValues,
        });
      } catch (e) {
        console.log("error fetching group by for publisher");
      }
    };
    fetchCountryGroups();
    const fetchTypeGroups = async () => {
      try {
        const { data } = await Axios.get(`${url}&group_by=type`);
        setTypeGroups({
          labels: data["group_by"].map((val) => val.key_display_name),
          values: data["group_by"].map((val) => val.count),
        });
      } catch (e) {
        console.log("error grouping sources by type");
      }
    };
    fetchTypeGroups();
  }, [url]);

  return (
    <div className="view container-fluid">
      <div className="row">
        <div className="col-12 rtl">
          <WhiteCard title={"اعمال فیلتر"}>
            <SourceListFilters onSearch={setSearch} />
          </WhiteCard>
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <WhiteCard title="منابع کشورهای مختلف" subtitle="بر حسب تعداد">
            <ApexPieChart
              labels={typeGroups.labels}
              values={typeGroups.values}
              height={"800"}
            />
          </WhiteCard>
          <WhiteCard title="منابع قاره های مختلف" subtitle="بر حسب تعداد">
            <ApexPieChart
              labels={continentGroups.labels}
              values={continentGroups.values}
            />
          </WhiteCard>
        </div>
        <div className="col-8">
          <WhiteCard
            title={"جدول منابع"}
            subtitle={"مرتب شده بر اساس تعداد پژوهش ها"}
          >
            <MateriaTable
              fetch_url={url}
              fields={[
                "display_name",
                "works_count",
                "cited_by_count",
                "country_code",
                "type",
              ]}
              classNames="english"
              titles={[
                "عنوان",
                "تعداد پژوهش ها",
                "تعداد ارجاعات",
                "کشور",
                "نوع",
              ]}
              redirect_url={"/sources"}
            />
          </WhiteCard>
        </div>
      </div>
    </div>
  );
};

export default SourcesView;
