import React, { useEffect, useState } from "react";
import FundersListFilters from "../components/funders/FundersListFilters";
import { BASE_FUNDERS_API_URL } from "../data_utils/base_api_urls";
import MateriaTable from "../components/utils/MaterialTable";
import WhiteCard from "../components/utils/WhiteCard";
import Axios from "../services/axios.custom";
import { convertCountryCodeToContinent } from "../data_utils/country_continent";
import ApexTreeMapChart from "../components/charts/ApexTreeMapChart";

const FundersView = () => {
  const [search, setSearch] = useState("");
  const [url, setUrl] = useState(BASE_FUNDERS_API_URL);

  const [fundersCountryGroups, setFundersCountryGroups] = useState({
    values: [],
    labels: [],
  });
  useEffect(() => {
    const fetchFundersCountryGroup = async () => {
      try {
        const { data } = await Axios.get(`${url}&group_by=country_code`);
        const byCountry = data["group_by"];
        const byContinent = {};
        for (let country of byCountry) {
          let cont = convertCountryCodeToContinent(country.key);
          if (cont in byContinent) {
            byContinent[cont] += country.count;
          } else {
            byContinent[cont] = country.count;
          }
        }
        let values = [];
        let labels = [];
        // TODO must create all the continent info and ...
        for (let [a, b] of Object.entries(byContinent)) {
          labels.push(a);
          values.push(b);
        }
        setFundersCountryGroups({ labels, values });
      } catch (e) {
        console.log("error fetching funders country grouping");
      }
    };
    fetchFundersCountryGroup();
  }, [url]);

  useEffect(() => {
    setUrl(`${BASE_FUNDERS_API_URL}search=${search}`);
  }, [search]);

  return (
    <div className="container-fluid mt-2">
      <div className="row">
        <div className="col-12">
          <WhiteCard title={"اعمال فیلتر"}>
            <FundersListFilters onSearch={setSearch} />
          </WhiteCard>
        </div>
        <div className="col-4">
          <WhiteCard
            title={"نمودار نقشه درختی تامین کنندگان مالی"}
            subtitle={"مرتب شده بر اساس تعداد تامین کننده در هر قاره"}
          >
            <ApexTreeMapChart
              height={600}
              values={fundersCountryGroups.values}
              labels={fundersCountryGroups.labels}
            />
          </WhiteCard>
        </div>
        <div className="col-8">
          <WhiteCard
            title={"جدول تامین کنندگان"}
            subtitle={"مرتب شده بر اساس تعداد ارجاعات"}
          >
            <MateriaTable
              fetch_url={url}
              fields={[
                "display_name",
                "grants_count",
                "cited_by_count",
                "works_count",
              ]}
              titles={[
                "عنوان",
                "تعداد کمک های مالی",
                "تعداد ارجاعات",
                "تعداد مقالات",
              ]}
              redirect_url={"/funders"}
            />
          </WhiteCard>
        </div>
      </div>
    </div>
  );
};

export default FundersView;
