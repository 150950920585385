import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Axios from "../services/axios.custom";
import MateriaTable from "../components/utils/MaterialTable";
import WhiteCard from "../components/utils/WhiteCard";
import ApexLineChart from "../components/charts/ApexLineChart";
import StaticMaterialTable from "../components/utils/StaticMaterialTable";
import CoauthoredCountries from "../components/countries/CoauthoredCountries";
import { CountryCodeToPersianName } from "../data_utils/country_continent";

const SingleCountryView = () => {
  const [country, setCountry] = useState({});
  const params = useParams();
  const [yearlyStats, setYearlyStats] = useState({
    years: [],
    cited_by_count: [],
    works_count: [],
  });

  useEffect(() => {
    const fetchCountryResearcherStats = async () => {
      try {
        const { data } = await Axios.get(
          `/v2/api/country_yearly_cites/${params.id}`
        );
        setYearlyStats(data.data);
      } catch (e) {
        console.log("error while calling country aggregate stats");
      }
    };
    fetchCountryResearcherStats();
  }, [params.id]);

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const { data } = await Axios.get(`/api/countries/${params.id}`);
        setCountry(data);
      } catch (e) {
        console.log(e);
      }
    };
    fetchCountry();
  }, [params.id]);
  return (
    <div key={params.id}>
      <div className="container-fluid my-2">
        <div className="row">
          <div className="col-12">
            <WhiteCard
              title={`کشور ${CountryCodeToPersianName[params.id]}`}
              subtitle={`اطلاعات و آمار کشور ${
                CountryCodeToPersianName[params.id]
              }`}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <WhiteCard title="تعداد ارجاعات در طول زمان">
              <ApexLineChart
                xData={yearlyStats.years}
                yData={yearlyStats.cited_by_count}
                name="تعداد ارجاعات"
              />
            </WhiteCard>
          </div>
          <div className="col-6">
            <WhiteCard title="تعداد مقالات در طول زمان">
              <ApexLineChart
                xData={yearlyStats.years}
                yData={yearlyStats.works_count}
                name="تعداد مقالات"
                color={"orangered"}
              />
            </WhiteCard>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <CoauthoredCountries countryId={params.id} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <WhiteCard
              title={`فهرست پژوهشگران کشور ${country.display_name}`}
              subtitle={"مرتب شده بر اساس تعداد ارجاعات"}
            >
              <MateriaTable
                fields={["display_name", "cited_by_count", "works_count"]}
                fetch_url={`/api/authors?filter=last_known_institutions.country_code:${params.id}&select=display_name,cited_by_count,works_count,id&sort=cited_by_count:desc`}
                redirect_url={"/authors"}
                titles={["نام پژوهشگر", "تعداد ارجاعات", "تعداد پژوهش ها"]}
              />
            </WhiteCard>
            <WhiteCard
              title={`فهرست مراکز تحقیقاتی کشور ${country.display_name}`}
              subtitle="مرتب شده بر اساس تعداد ارجاعات"
            >
              <MateriaTable
                fields={["display_name", "cited_by_count", "works_count"]}
                fetch_url={`/api/institutions?filter=country_code:${params.id}&select=display_name,cited_by_count,works_count,id&sort=cited_by_count:desc`}
                redirect_url={"/institutions"}
                titles={["نام مرکز پژوهشی", "تعداد ارجاعات", "تعداد پژوهش ها"]}
              />
            </WhiteCard>
            <WhiteCard
              title={`فهرست پژوهش های کشور ${country.display_name}`}
              subtitle="مرتب شده بر اساس تعداد ارجاعات"
            >
              <MateriaTable
                fields={["display_name", "cited_by_count", "publication_year"]}
                fetch_url={`/api/works?filter=authorships.countries:${params.id}&select=display_name,cited_by_count,publication_year,id&sort=cited_by_count:desc`}
                redirect_url={"/works"}
                titles={["عنوان پژوهش", "تعداد ارجاعات", "سال انتشار"]}
              />
            </WhiteCard>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleCountryView;
