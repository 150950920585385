import React, { useEffect, useState } from "react";
import { Graph } from "react-d3-graph";
import Axios from "../../services/axios.custom";
import { convertTreeTod3GraphData } from "../../data_utils/concept_tree_utils";

const StaticGraph = ({ concepts, sizeFactor = 1000 }) => {
    const config = {
        directed: true,
        // automaticRearrangeAfterDropNode: true,
        collapsible: true,
        // height: window.innerHeight,
        height: 500,
        width: 1000,
        highlightDegree: 2,
        highlightOpacity: 0.2,
        linkHighlightBehavior: true,
        maxZoom: 12,
        minZoom: 0.05,
        nodeHighlightBehavior: true, // comment this to reset nodes positions to work
        panAndZoom: false,
        staticGraph: false,
        // width: window.innerWidth,
        // width:
        d3: {
            alphaTarget: 0.05,
            gravity: -250,
            linkLength: 120,
            linkStrength: 2,
        },
        node: {
            color: "#d3d3d3",
            fontColor: "black",
            fontSize: 10,
            fontWeight: "normal",
            highlightColor: "red",
            highlightFontSize: 14,
            highlightFontWeight: "bold",
            highlightStrokeColor: "red",
            highlightStrokeWidth: 1.5,
            labelProperty: (n) => n.name, //(n.name ? `${n.id} - ${n.name}` : n.id),
            mouseCursor: "crosshair",
            opacity: 0.9,
            renderLabel: true,
            size: sizeFactor,
            strokeColor: "none",
            strokeWidth: 1.5,
            svg: "",
            symbolType: "circle",
            viewGenerator: null,
        },
        link: {
            color: "lightgray",
            highlightColor: "red",
            mouseCursor: "pointer",
            opacity: 1,
            semanticStrokeWidth: true,
            strokeWidth: 3,
            type: "STRAIGHT",
        },
    };
    const ref = React.useRef(null);
    const [updateKey, setUpdateKey] = useState(0);
    const [conceptGraph, setConceptGraph] = useState({ nodes: [], links: [] });

    useEffect(() => {
        const filterConceptGraph = async (filters) => {
            try {
                const { data } = await Axios.post(`/filter_graph`, {
                    filters,
                });
                const graph = convertTreeTod3GraphData(data.data, 100, 10000);
                graph.nodes[0].color = "black";
                setConceptGraph(graph);
            } catch (e) {
                console.error(e);
            }
        };
        // if (!Object.keys(author).length) {
        //     return;
        // }
        let filters = [];
        for (let concept of concepts) {
            filters.push([concept.display_name, concept.score]);
        }
        filterConceptGraph(filters);
    }, [concepts]);

    useEffect(() => {
        setUpdateKey((k) => ++k);
        // ref.current.resetNodesPositions();
    }, [conceptGraph.nodes]);

    return (
        <div key={updateKey}>
            <Graph
                key={updateKey}
                config={config}
                id="author-graph"
                ref={ref}
                data={conceptGraph}
            />
        </div>
    );
};

export default StaticGraph;
