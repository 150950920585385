import Navbar from "./components/Navbar";
import ConceptGraphView from "./views/ConceptGraphView";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import PrivateRoute from "./components/PrivateRoute";
import LoginView from "./views/LoginView";
import AuthorsView from "./views/AuthorsView";
import SingleAuthorView from "./views/SingleAuthorView";
import SingleWorkView from "./views/SingleWorkView";
import WorksView from "./views/WorksView";
import InstitutionsView from "./views/InstitutionsView";
import SingleInstitutionView from "./views/SingleInstitutionView";
import CountriesView from "./views/CountriesView";
import FundersView from "./views/FundersView";
import SingleFunderView from "./views/SingleFunderView";
import SingleCountryView from "./views/SingleCountryView";
import PublishersView from "./views/PublishersView";
import SinglePublisherView from "./views/SinglePublisherView";
import SourcesView from "./views/SourcesView";
import SingleSourceView from "./views/SingleSourceView";
import KeywordsView from "./views/KeywordsView";
import SingleKeywordView from "./views/SingleKeywordView";
import SemanticSearchWorksView from "./views/SemanticSearchWorksView";

function App() {
  const { isLoggedIn } = useSelector((state) => state.auth);
  return (
    <>
      {isLoggedIn && <Header />}
      <div className="d-flex">
        <div className="main flex-grow-1">
          <Routes>
            <Route path="/login" Component={LoginView} />
            <Route
              path="/concepts"
              element={
                <PrivateRoute>
                  <ConceptGraphView />
                </PrivateRoute>
              }
            />
            <Route
              path="/authors"
              element={
                <PrivateRoute>
                  <AuthorsView />
                </PrivateRoute>
              }
            />
            <Route
              path="/authors/:id"
              element={
                <PrivateRoute>
                  <SingleAuthorView />
                </PrivateRoute>
              }
            />
            <Route
              path="/works"
              element={
                <PrivateRoute>
                  <WorksView />
                </PrivateRoute>
              }
            />
            <Route
              path="/semantic-search"
              element={
                <PrivateRoute>
                  <SemanticSearchWorksView />
                </PrivateRoute>
              }
            />
            <Route
              path="/works/:id"
              element={
                <PrivateRoute>
                  <SingleWorkView />
                </PrivateRoute>
              }
            />
            <Route
              path="/institutions"
              element={
                <PrivateRoute>
                  <InstitutionsView />
                </PrivateRoute>
              }
            />
            <Route
              path="/institutions/:id"
              element={
                <PrivateRoute>
                  <SingleInstitutionView />
                </PrivateRoute>
              }
            />
            <Route
              path="/countries"
              element={
                <PrivateRoute>
                  <CountriesView />
                </PrivateRoute>
              }
            />
            <Route
              path="/countries/:id"
              element={
                <PrivateRoute>
                  <SingleCountryView />
                </PrivateRoute>
              }
            />
            <Route
              path="/funders"
              element={
                <PrivateRoute>
                  <FundersView />
                </PrivateRoute>
              }
            />
            <Route
              path="/funders/:id"
              element={
                <PrivateRoute>
                  <SingleFunderView />
                </PrivateRoute>
              }
            />
            <Route
              path="/publishers"
              element={
                <PrivateRoute>
                  <PublishersView />
                </PrivateRoute>
              }
            />
            <Route
              path="/publishers/:id"
              element={
                <PrivateRoute>
                  <SinglePublisherView />
                </PrivateRoute>
              }
            />
            <Route
              path="/sources"
              element={
                <PrivateRoute>
                  <SourcesView />
                </PrivateRoute>
              }
            />
            <Route
              path="/sources/:id"
              element={
                <PrivateRoute>
                  <SingleSourceView />
                </PrivateRoute>
              }
            />
            <Route
              path="/keywords"
              element={
                <PrivateRoute>
                  <KeywordsView />
                </PrivateRoute>
              }
            />
            <Route
              path="/keywords/:id"
              element={
                <PrivateRoute>
                  <SingleKeywordView />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<LoginView />} />
          </Routes>
        </div>
        {isLoggedIn && <Navbar />}
      </div>
    </>
  );
}

export default App;
