import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import axios from "axios";

const AuthorHeatMap = ({ authorId }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get(
        `/v2/api/author_subject_change/${authorId}`
      );
      // let length = data[0]["data"].length;
      // if (length < 10) {
      //   for
      // }
      // let newData = [];

      // for (let record of data) {
      //   let counter = 1;
      //   for (let i = 0; i < 9 - length; i++) {
      //     record["data"].push({ ...record["data"][record["data"].length - 1] });
      //     record["data"][record["data"].length - 1].x += 1;
      //     // counter += 1;
      //   }
      // }
      setData(data);

      // const { links } = response.data;

      // Prepare data for the bubble chart
      // const subjectWorkData = {};

      // links.forEach((link) => {
      //   if (!subjectWorkData[link.source]) {
      //     subjectWorkData[link.source] = [];
      //   }
      //   // Simulating a year axis for time
      //   const year = Math.floor(Math.random() * (2023 - 2000 + 1)) + 2000;
      //   subjectWorkData[link.source].push({
      //     x: year,
      //     y: Math.random() * 10,
      //     z: link.value,
      //   });
      // });

      // setData(
      //   Object.keys(subjectWorkData).map((subject) => ({
      //     name: subject,
      //     data: subjectWorkData[subject],
      //   }))
      // );
    };
    fetchData();
  }, [authorId]);

  let years = new Set();
  for (let record of data) {
    for (let rec of record["data"]) {
      years.add(rec.x);
    }
  }

  const chartOptions = {
    // title: {
    //   text: "Author Subject Work Over Time",
    //   align: "center",
    // },
    xaxis: {
      tickAmount: years.size,
      // type: "category",
      title: {
        text: "Year",
      },
    },
    // yaxis: {
    // max: 10,
    // title: {
    // text: "Random Metric (optional, can be work amount or citations)",
    // },
    // },
    tooltip: {
      shared: false,
      intersect: true,
      theme: "dark",
    },
    dataLabels: {
      enabled: false,
    },
  };
  console.log({ years });
  return (
    <div id="chart">
      {data.length && (
        <ReactApexChart
          options={{
            xaxis: {
              // axisTicks: Array.from(years),
              // tickAmount: 1,
              axisTicks: Array.from(years),
              min: Math.min(...years.values()),
              max: Math.max(...years.values()),
            },
            tooltip: {
              shared: false,
              intersect: true,
              theme: "dark",
            },
            dataLabels: {
              enabled: false,
            },
          }}
          series={data}
          type="heatmap"
          height={400}
        />
      )}
    </div>
  );
};

export default AuthorHeatMap;
