import React, { useEffect, useState } from "react";
import { NAMED_COLORS } from "../data_utils/colors";
import Axios, { REACT_APP_BACKEND_URL } from "../services/axios.custom";
import { useParams } from "react-router-dom";
import { englishNumberToPersian } from "../data_utils/language_converter";
import AuthorStatsCard from "../components/single-author-view/AuthorStatsCard";
import ApexLineChart from "../components/charts/ApexLineChart";
import LoadingIndicator from "../components/utils/LoadingIndicator";
import FunderStatsCard from "../components/funders/FunderStatsCard";

const config = {
  directed: true,
  // automaticRearrangeAfterDropNode: true,
  collapsible: true,
  // height: window.innerHeight,
  height: 500,
  highlightDegree: 2,
  highlightOpacity: 0.2,
  linkHighlightBehavior: true,
  maxZoom: 12,
  minZoom: 0.05,
  nodeHighlightBehavior: true, // comment this to reset nodes positions to work
  panAndZoom: false,
  staticGraph: false,
  // width: window.innerWidth,
  // width:
  d3: {
    alphaTarget: 0.05,
    gravity: -250,
    linkLength: 120,
    linkStrength: 2,
  },
  node: {
    color: "#d3d3d3",
    fontColor: "black",
    fontSize: 10,
    fontWeight: "normal",
    highlightColor: "red",
    highlightFontSize: 14,
    highlightFontWeight: "bold",
    highlightStrokeColor: "red",
    highlightStrokeWidth: 1.5,
    labelProperty: (n) => n.name, //(n.name ? `${n.id} - ${n.name}` : n.id),
    mouseCursor: "crosshair",
    opacity: 0.9,
    renderLabel: true,
    size: 200,
    strokeColor: "none",
    strokeWidth: 1.5,
    svg: "",
    symbolType: "circle",
    viewGenerator: null,
  },
  link: {
    color: "lightgray",
    highlightColor: "red",
    mouseCursor: "pointer",
    opacity: 1,
    semanticStrokeWidth: true,
    strokeWidth: 3,
    type: "STRAIGHT",
  },
};

const SingleFunderView = () => {
  const [funder, setFunder] = useState({});
  const params = useParams();

  useEffect(() => {
    const fetchFunder = async () => {
      try {
        const { data } = await Axios.get(`/api/funders/${params.id}`);
        // dispatch(setSingleAuthor(data));
        setFunder(data);
      } catch (e) {
        console.log(e);
      }
    };
    fetchFunder();
  }, []);

  const renderStats = () => {
    const stats = [
      {
        name: "h_index",
        display_name: "H Index",
        value: funder.summary_stats.h_index,
        color: NAMED_COLORS.LIGHT_PINK,
      },
      {
        name: "i10_index",
        display_name: "I10 Index",
        value: funder.summary_stats.i10_index,
        color: NAMED_COLORS.LIGHT_GREEN,
      },
      {
        name: "total_citations",
        display_name: "کل ارجاعات",
        value: funder.cited_by_count,
        color: NAMED_COLORS.LIGHT_BLUE,
      },
      {
        name: "Count of Works",
        display_name: "تعداد مقالات",
        value: funder.works_count,
        color: NAMED_COLORS.LIGHT_PURPLE,
      },
      {
        name: "Grants Counts",
        display_name: "تعداد تامین های مالی",
        value: funder.grants_count,
        color: NAMED_COLORS.LIGHT_YELLOW,
      },
    ];
    stats.forEach((stat) => {
      stat.value = englishNumberToPersian(stat.value);
    });
    return <FunderStatsCard stats={stats} />;
  };
  let chartData = [];
  if (funder.counts_by_year) {
    chartData = funder.counts_by_year;
    chartData.sort((a, b) => (a.year > b.year ? 1 : -1));
  }
  let xData = chartData.map((x) => x.year);
  let worksCount = chartData.map((x) => x.works_count);
  let citations = chartData.map((x) => x.cited_by_count);
  return (
    <div className="container-fluid my-2">
      <div className="row">
        <div className="col-12">
          {Object.keys(funder).length ? (
            <>
              <div className="white_card rounded-bg">
                <div className="white_card_header">
                  <div className="author-info">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="main-title">
                        <h3 className="m-0">{funder.display_name}</h3>
                        <span>{funder.description}</span>
                      </div>
                      <h1 className="text-title"></h1>
                      <img
                        className="inline-flag"
                        src={funder.image_thumbnail_url}
                        alt="country flag"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex mt-2">
                <div className="d-flex flex-column flex-grow-1">
                  <div className="white_card rounded-bg flex-grow-1 me-2 ">
                    <div className="white_card_header rtl">
                      <div className="main-title">
                        <h3 className="m-0">تعداد پژوهش ها در طول زمان</h3>
                        <span>از سال ۲۰۱۲ الی ۲۰۲۴</span>
                      </div>
                    </div>
                    <div className="author-charts" style={{ height: "200px" }}>
                      <ApexLineChart
                        xData={xData}
                        yData={worksCount}
                        name={"تعداد پژوهش ها"}
                      />
                    </div>
                  </div>

                  <div className="white_card rounded-bg flex-grow-1 me-2 mt-2">
                    <div className="white_card_header rtl">
                      <div className="main-title">
                        <h3 className="m-0">تعداد ارجاعات در طول زمان</h3>
                        <span>از سال ۲۰۱۲ الی ۲۰۲۴</span>
                      </div>
                    </div>
                    <div className="author-charts" style={{ height: "200px" }}>
                      <ApexLineChart
                        xData={xData}
                        yData={citations}
                        name={"تعداد ارجاعات"}
                        color={"orangered"}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ minWidth: "450px" }}>{renderStats()}</div>
              </div>
            </>
          ) : (
            <LoadingIndicator />
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleFunderView;
