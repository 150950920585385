const contryContinentMapping = {
    "US": "North America",
    "CN": "Asia",
    "GB": "Europe",
    "DE": "Europe",
    "JP": "Asia",
    "FR": "Europe",
    "IN": "Asia",
    "CA": "North America",
    "IT": "Europe",
    "BR": "South America",
    "AU": "Oceania",
    "ES": "Europe",
    "RU": "Europe",
    "ID": "Asia",
    "NL": "Europe",
    "KR": "Asia",
    "PL": "Europe",
    "CH": "Europe",
    "TR": "Asia",
    "SE": "Europe",
    "BE": "Europe",
    "IR": "Asia",
    "TW": "Asia",
    "MX": "North America",
    "DK": "Europe",
    "AT": "Europe",
    "IL": "Asia",
    "PT": "Europe",
    "NO": "Europe",
    "FI": "Europe",
    "ZA": "Africa",
    "CZ": "Europe",
    "MY": "Asia",
    "EG": "Africa",
    "GR": "Europe",
    "SG": "Asia",
    "AR": "South America",
    "UA": "Europe",
    "SA": "Asia",
    "NZ": "Oceania",
    "PK": "Asia",
    "CO": "South America",
    "IE": "Europe",
    "HU": "Europe",
    "HK": "Asia",
    "CL": "South America",
    "NG": "Africa",
    "RO": "Europe",
    "TH": "Asia",
    "HR": "Europe",
    "SK": "Europe",
    "BD": "Asia",
    "RS": "Europe",
    "VN": "Asia",
    "IQ": "Asia",
    "MA": "Africa",
    "BG": "Europe",
    "PE": "South America",
    "SI": "Europe",
    "TN": "Africa",
    "DZ": "Africa",
    "CU": "North America",
    "AE": "Asia",
    "PA": "North America",
    "EC": "South America",
    "PH": "Asia",
    "KE": "Africa",
    "ET": "Africa",
    "JO": "Asia",
    "VE": "South America",
    "LT": "Europe",
    "GH": "Africa",
    "NP": "Asia",
    "KZ": "Asia",
    "QA": "Asia",
    "EE": "Europe",
    "CR": "North America",
    "BY": "Europe",
    "LB": "Asia",
    "CY": "Asia",
    "LK": "Asia",
    "LU": "Europe",
    "UZ": "Asia",
    "LV": "Europe",
    "UY": "South America",
    "PR": "North America",
    "TZ": "Africa",
    "UG": "Africa",
    "CM": "Africa",
    "KW": "Asia",
    "OM": "Asia",
    "MO": "Asia",
    "AZ": "Asia",
    "IS": "Europe",
    "BA": "Europe",
    "MN": "Asia",
    "TJ": "Asia",
    "AM": "Asia",
    "SD": "Africa",
    "BO": "South America",
    "GE": "Asia",
    "ZW": "Africa",
    "MK": "Europe",
    "SN": "Africa",
    "MD": "Europe",
    "CI": "Africa",
    "YE": "Asia",
    "SS": "Africa",
    "ZM": "Africa",
    "KH": "Asia",
    "MZ": "Africa",
    "PS": "Asia",
    "BJ": "Africa",
    "MT": "Europe",
    "PY": "South America",
    "BH": "Asia",
    "GT": "North America",
    "AL": "Europe",
    "BF": "Africa",
    "MW": "Africa",
    "ML": "Africa",
    "JM": "North America",
    "MM": "Asia",
    "SY": "Asia",
    "CD": "Africa",
    "BW": "Africa",
    "NE": "Africa",
    "LY": "Africa",
    "RE": "Africa",
    "BN": "Asia",
    "BI": "Africa",
    "VG": "North America",
    "RW": "Africa",
    "ME": "Europe",
    "XK": "Europe",
    "CG": "Africa",
    "TT": "North America",
    "NI": "North America",
    "KG": "Asia",
    "SV": "North America",
    "MG": "Africa",
    "DO": "North America",
    "NA": "Africa",
    "HN": "North America",
    "FJ": "Oceania",
    "GP": "North America",
    "MU": "Africa",
    "AF": "Asia",
    "PG": "Oceania",
    "TG": "Africa",
    "AO": "Africa",
    "GM": "Africa",
    "GA": "Africa",
    "GD": "North America",
    "GN": "Africa",
    "LA": "Asia",
    "SL": "Africa",
    "MC": "Europe",
    "BB": "North America",
    "LI": "Europe",
    "NC": "Oceania",
    "ST": "Africa",
    "PF": "Oceania",
    "MQ": "North America",
    "GL": "North America",
    "GF": "South America",
    "BT": "Asia",
    "AG": "North America",
    "GU": "Oceania",
    "SZ": "Africa",
    "TD": "Africa",
    "HT": "North America",
    "SO": "Africa",
    "GY": "South America",
    "KN": "North America",
    "LS": "Africa",
    "BS": "North America",
    "MF": "North America",
    "CW": "North America",
    "LR": "Africa",
    "TM": "Asia",
    "MV": "Asia",
    "GW": "Africa",
    "BM": "North America",
    "FO": "Europe",
    "VI": "North America",
    "SR": "South America",
    "MR": "Africa",
    "CF": "Africa",
    "ER": "Africa",
    "BZ": "North America",
    "DM": "North America",
    "KY": "North America",
    "KP": "Asia",
    "GI": "Europe",
    "SC": "Africa",
    "CV": "Africa",
    "TL": "Asia",
    "VA": "Europe",
    "SM": "Europe",
    "AD": "Europe",
    "AI": "North America",
    "AQ": "Antarctica",
    "AS": "Oceania",
    "AW": "North America",
    "AX": "Europe",
    "BL": "North America",
    "BQ": "North America",
    "BV": "Antarctica",
    "CC": "Asia",
    "CK": "Oceania",
    "CX": "Asia",
    "DJ": "Africa",
    "FK": "South America",
    "FM": "Oceania",
    "GG": "Europe",
    "GQ": "Africa",
    "GS": "South America",
    "HM": "Antarctica",
    "IM": "Europe",
    "IO": "Asia",
    "JE": "Europe",
    "KI": "Oceania",
    "KM": "Africa",
    "LC": "North America",
    "MH": "Oceania",
    "MP": "Oceania",
    "MS": "North America",
    "NF": "Oceania",
    "NR": "Oceania",
    "NU": "Oceania",
    "PM": "North America",
    "PN": "Oceania",
    "PW": "Oceania",
    "SB": "Oceania",
    "SH": "Africa",
    "SJ": "Europe",
    "SX": "North America",
    "TC": "North America",
    "TK": "Oceania",
    "TO": "Oceania",
    "TV": "Oceania",
    "VC": "North America",
    "VU": "Oceania",
    "WF": "Oceania",
    "WS": "Oceania",
    "YT": "Africa"
}

export const CountryCodeToPersianName = {
    DZ: "الجزایر",
    AO: "آنگولا",
    BJ: "بنین",
    BW: "بوتسوانا",
    BF: "بورکینافاسو",
    BI: "بوروندی",
    CM: "کامرون",
    CV: "کیپ ورد",
    CF: "جمهوری آفریقای مرکزی",
    TD: "چاد",
    KM: "کومور",
    CG: "کنگو",
    CD: "جمهوری دموکراتیک کنگو",
    CI: "ساحل عاج",
    DJ: "جیبوتی",
    EG: "مصر",
    GQ: "گینه استوایی",
    ER: "اریتره",
    ET: "اتیوپی",
    GA: "گابن",
    GM: "گامبیا",
    GH: "غنا",
    GN: "گینه",
    GW: "گینه بیسائو",
    KE: "کنیا",
    LS: "لسوتو",
    LR: "لیبریا",
    LY: "لیبی",
    MG: "ماداگاسکار",
    MW: "مالاوی",
    ML: "مالی",
    MR: "موریتانی",
    MU: "موریس",
    YT: "مایوت",
    MA: "مراکش",
    MZ: "موزامبیک",
    NA: "نامیبیا",
    NE: "نیجر",
    NG: "نیجریه",
    RE: "رئونیون",
    RW: "رواندا",
    SH: "سنت هلنا، اسنشن و تریستان دا کونیا",
    ST: "سائوتومه و پرنسیپ",
    SN: "سنگال",
    SC: "سیشل",
    SL: "سیرالئون",
    SO: "سومالی",
    ZA: "آفریقای جنوبی",
    SS: "سودان جنوبی",
    SD: "سودان",
    SZ: "اسواتینی",
    TZ: "تانزانیا",
    TG: "توگو",
    TN: "تونس",
    UG: "اوگاندا",
    ZM: "زامبیا",
    ZW: "زیمبابوه",
    AQ: "جنوبگان",
    BV: "جزیره بووه",
    HM: "جزایر هرد و مک‌دونالد",
    AF: "افغانستان",
    AM: "ارمنستان",
    AZ: "آذربایجان",
    BH: "بحرین",
    BD: "بنگلادش",
    BT: "بوتان",
    IO: "قلمرو بریتانیا در اقیانوس هند",
    BN: "برونئی دارالسلام",
    KH: "کامبوج",
    CN: "چین",
    CX: "جزیره کریسمس",
    CC: "جزایر کوکوس (کیلینگ)",
    CY: "قبرس",
    GE: "گرجستان",
    HK: "هنگ کنگ",
    IN: "هند",
    ID: "اندونزی",
    IR: "ایران",
    IQ: "عراق",
    IL: "اسرائیل",
    JP: "ژاپن",
    JO: "اردن",
    KZ: "قزاقستان",
    KP: "کره شمالی",
    KR: "کره جنوبی",
    KW: "کویت",
    KG: "قرقیزستان",
    LA: "لائوس",
    LB: "لبنان",
    MO: "ماکائو",
    MY: "مالزی",
    MV: "مالدیو",
    MN: "مغولستان",
    MM: "میانمار",
    NP: "نپال",
    OM: "عمان",
    PK: "پاکستان",
    PS: "فلسطین",
    PH: "فیلیپین",
    QA: "قطر",
    SA: "عربستان سعودی",
    SG: "سنگاپور",
    LK: "سریلانکا",
    SY: "سوریه",
    TW: "تایوان",
    TJ: "تاجیکستان",
    TH: "تایلند",
    TL: "تیمور-لسته",
    TR: "ترکیه",
    TM: "ترکمنستان",
    AE: "امارات متحده عربی",
    UZ: "ازبکستان",
    VN: "ویتنام",
    YE: "یمن",
    AX: "جزایر آلاند",
    AL: "آلبانی",
    AD: "آندورا",
    AT: "اتریش",
    BY: "بلاروس",
    BE: "بلژیک",
    BA: "بوسنی و هرزگوین",
    BG: "بلغارستان",
    HR: "کرواسی",
    CZ: "جمهوری چک",
    DK: "دانمارک",
    EE: "استونی",
    FO: "جزایر فارو",
    FI: "فنلاند",
    FR: "فرانسه",
    DE: "آلمان",
    GI: "جبل‌الطارق",
    GR: "یونان",
    GG: "گرنزی",
    HU: "مجارستان",
    IS: "ایسلند",
    IE: "ایرلند",
    IM: "جزیره من",
    IT: "ایتالیا",
    JE: "جرزی",
    XK: "کوزوو",
    LV: "لتونی",
    LI: "لیختن‌اشتاین",
    LT: "لیتوانی",
    LU: "لوکزامبورگ",
    MK: "مقدونیه شمالی",
    MT: "مالت",
    MD: "مولداوی",
    MC: "موناکو",
    ME: "مونته‌نگرو",
    NL: "هلند",
    NO: "نروژ",
    PL: "لهستان",
    PT: "پرتغال",
    RO: "رومانی",
    RU: "روسیه",
    SM: "سن مارینو",
    RS: "صربستان",
    SK: "اسلواکی",
    SI: "اسلوونی",
    ES: "اسپانیا",
    SJ: "اسوالبارد و یان ماین",
    SE: "سوئد",
    CH: "سوئیس",
    UA: "اوکراین",
    GB: "بریتانیا",
    VA: "واتیکان",
    AI: "آنگویلا",
    AG: "آنتیگوا و باربودا",
    AW: "آروبا",
    BS: "باهاما",
    BB: "باربادوس",
    BZ: "بلیز",
    BM: "برمودا",
    BQ: "بونیر، سنت یوستیشس و سابا",
    CA: "کانادا",
    KY: "جزایر کیمن",
    CR: "کاستاریکا",
    CU: "کوبا",
    CW: "کوراسائو",
    DM: "دومینیکا",
    DO: "جمهوری دومینیکن",
    SV: "السالوادور",
    GL: "گرینلند",
    GD: "گرنادا",
    GP: "گوادلوپ",
    GT: "گواتمالا",
    HT: "هائیتی",
    HN: "هندوراس",
    JM: "جامائیکا",
    MQ: "مارتینیک",
    MX: "مکزیک",
    MS: "مونتسرات",
    NI: "نیکاراگوئه",
    PA: "پاناما",
    PR: "پورتوریکو",
    BL: "سن بارتلمی",
    KN: "سنت کیتس و نویس",
    LC: "سنت لوسیا",
    MF: "سنت مارتن (بخش فرانسوی)",
    SX: "سنت مارتن (بخش هلندی)",
    PM: "سن پیر و میکلون",
    VC: "سنت وینسنت و گرنادین‌ها",
    TT: "ترینیداد و توباگو",
    TC: "جزایر تورکس و کایکوس",
    US: "ایالات متحده",
    VG: "جزایر ویرجین بریتانیا",
    VI: "جزایر ویرجین ایالات متحده",
    AS: "ساموآی آمریکا",
    AU: "استرالیا",
    CK: "جزایر کوک",
    FJ: "فیجی",
    PF: "پلی‌نزی فرانسه",
    GU: "گوام",
    KI: "کیریباتی",
    MH: "جزایر مارشال",
    FM: "ایالات فدرال میکرونزی",
    NR: "نائورو",
    NC: "کالدونیای جدید",
    NZ: "نیوزیلند",
    NU: "نیووی",
    NF: "جزیره نورفک",
    MP: "جزایر ماریانای شمالی",
    PW: "پالائو",
    PG: "پاپوآ گینه نو",
    WS: "ساموآ",
    SB: "جزایر سلیمان",
    TK: "توکلائو",
    TO: "تونگا",
    TV: "تووالو",
    VU: "وانواتو",
    WF: "والیس و فوتونا",
    AR: "آرژانتین",
    BO: "بولیوی",
    BR: "برزیل",
    CL: "شیلی",
    CO: "کلمبیا",
    EC: "اکوادور",
    FK: "جزایر فالکلند",
    GF: "گویان فرانسه",
    GY: "گویان",
    PY: "پاراگوئه",
    PE: "پرو",
    SR: "سورینام",
    UY: "اروگوئه",
    VE: "ونزوئلا"
};


export const convertCountryCodeToContinent = countryCode => {
    return contryContinentMapping[countryCode]
}
  