import React, { useState } from 'react'
import './SearchButton.css';

const SearchButton = ({ onSearchConcept, title, top }) => {
    const [searchText, setSearchText] = useState('');
    const onTextChanged = e => {
        setSearchText(e.target.value);
    }

    const onSubmitForm = () => {
        onSearchConcept(searchText)
    }
    
  return (
    <div style={{top}} className="search-box-container d-flex align-items-center shadow">
        <input onChange={onTextChanged} type="tex" className="form-control" placeholder={'Concept...'} />
        <button onClick={onSubmitForm} type="submit" className="ms-3 btn btn-primary">{title}</button>
    </div>
  )
}

export default SearchButton