import React, { useEffect, useState } from "react";
import { BASE_KEYWORDS_API_URL } from "../data_utils/base_api_urls";
import WhiteCard from "../components/utils/WhiteCard";
import KeywordsListFilters from "../components/keywords/KeywordsListFilters";
import MateriaTable from "../components/utils/MaterialTable";

const KeywordsView = () => {
  const [search, setSearch] = useState("");
  const [url, setUrl] = useState(BASE_KEYWORDS_API_URL);

  //   const [countryGroups, setCountryGroups] = useState([]);
  //   useEffect(() => {
  //     const fetchCountryGroups = async () => {
  //       try {
  //         const { data } = await Axios.get(`${url}&group_by=country_code`);
  //         setCountryGroups(data["group_by"]);
  //       } catch (e) {
  //         console.log("error fetching group by published year for works");
  //       }
  //     };
  //     fetchCountryGroups();
  //   }, [url]);

  //   const [countinentGroups, setContinentGroups] = useState([]);
  //   useEffect(() => {
  //     const fetchContinentGroups = async () => {
  //       try {
  //         const { data } = await Axios.get(`${url}&group_by=continent`);
  //         setContinentGroups(data["group_by"]);
  //       } catch (e) {
  //         console.log("error fetching group by published year for works");
  //       }
  //     };
  //     fetchContinentGroups();
  //   }, [url]);

  useEffect(() => {
    setUrl(`${BASE_KEYWORDS_API_URL}search=${search}`);
  }, [search]);
  return (
    <div className="container-fluid mt-2">
      <div className="row">
        <div className="col-12">
          <WhiteCard title="اعمال فیلتر">
            <KeywordsListFilters onSearch={setSearch} />
          </WhiteCard>
          <div className="row">
            <div className="col-4"></div>
            <div className="col-8">
              <WhiteCard
                title={"جدول کلید واژه ها"}
                subtitle="مرتب شده بر اساس تعداد مقالات"
              >
                <MateriaTable
                  fetch_url={url}
                  fields={["display_name", "cited_by_count", "works_count"]}
                  titles={["عنوان", "تعداد ارجاعات", "تعداد مقالات"]}
                  redirect_url={"/keywords"}
                />
              </WhiteCard>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeywordsView;
