import Axios from "axios";
import React, { useEffect, useState } from "react";
import {
  convertTreeTod3GraphData,
  graphNodeChildren,
  selectGraphCenter,
  toggleKey,
} from "../data_utils/concept_tree_utils";
import ConceptMap from "../components/ConceptGraph";
import RadioFloatingBox from "../components/RadioFloatingBox";
import SearchButton from "../components/SearchButton";
import LoadingIndicator from "../components/utils/LoadingIndicator";
import { REACT_APP_BACKEND_URL } from "../services/axios.custom";
import WhiteCard from "../components/utils/WhiteCard";

const ConceptGraphView = () => {
  const [graphData, setGraphData] = useState({ nodes: [], links: [] });
  const [originalGraphData, setOriginalGraphData] = useState({});
  const [curGraph, setCurGraph] = useState({ nodes: [], links: [] });
  const [nodeChildren, setNodeChildren] = useState({});
  const [nodePropertyMapping, setNodePropertyMapping] = useState({});
  const [loading, setLoading] = useState(false);
  const fetchConceptTreeData = async (type) => {
    setLoading(true);
    try {
      const { data } = await Axios.get(
        type === "growth"
          ? `${REACT_APP_BACKEND_URL}/mini_concept_tree`
          : `${REACT_APP_BACKEND_URL}/mini_concept_tree_svg`
      );
      // console.log({data: data.data})
      setOriginalGraphData(data.data);
      let graphData = convertTreeTod3GraphData(data.data);
      graphData.nodes[0]["color"] = "black";
      // console.log({graphData})
      // graphData.nodes = graphData.nodes.map(d => ({...d, color: '#3300aa'}))
      // console.log({ graphData });
      setGraphData(graphData);
      let nodeProperty = {};
      for (let node of graphData.nodes) {
        nodeProperty[node.name] = node;
      }
      // console.log({graphData})
      setNodePropertyMapping(nodeProperty);
      setCurGraph(selectGraphCenter(graphData, nodeProperty));
      setNodeChildren(graphNodeChildren(graphData));
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchConceptTreeData("growth");
  }, []);
  // console.log(graphData)
  const onNodeClicked = (nodeId) => {
    const changedGraph = toggleKey(
      nodeId,
      curGraph,
      nodeChildren,
      nodePropertyMapping
    );
    // console.log({changedGraph})

    setCurGraph(changedGraph);
  };

  const partialFilterGraph = (term) => {
    const partialSearch = (node) => {
      let anyFound = false;
      let res = {};
      if (
        node.full_description &&
        node.full_description.toLowerCase().search(term.toLowerCase()) > -1
      ) {
        console.log(node);
        anyFound = true;
        // res = {
        //     ...nodePropertyMapping[node.name],
        //     children: []
        // };
        // return [true, nodePropertyMapping[node.name]];
      }
      res = {
        ...nodePropertyMapping[node.name],
        children: [],
      };
      if (!node.children) {
        return [anyFound, res];
      }
      for (let child of node.children) {
        const [found, nested] = partialSearch(child);

        if (found && Object.keys(nested).length) {
          anyFound = true;
          res.children.push(nested);
        }
      }
      if (anyFound) {
        return [true, res];
      }
      return [false, {}];
    };

    return partialSearch(originalGraphData)[1];
  };

  const filterGraph = (term) => {
    const dfs = (node) => {
      // console.log(node.name.toLowerCase(), term.toLowerCase())
      if (node.name.toLowerCase() === term.toLowerCase()) {
        return [true, nodePropertyMapping[node.name]];
      }
      if (!node.children) {
        return [false, {}];
      }
      let res = {
        // name: node,
        ...nodePropertyMapping[node.name],
        children: [],
      };
      let anyFound = false;
      for (let child of node.children) {
        const [found, nested] = dfs(child);
        // console.log({nested})

        if (found && Object.keys(nested).length) {
          anyFound = true;
          res.children.push(nested);
          // res = {name: node, ...nested, ...res}
        }
      }
      if (anyFound) {
        return [true, res];
      }
      return [false, {}];
    };

    return dfs(originalGraphData)[1];
  };
  const filterGraphContent = (term, isPartial) => {
    if (!term) {
      setCurGraph(selectGraphCenter(graphData, nodePropertyMapping));
    } else {
      if (isPartial) {
        let filteredGraph = partialFilterGraph(term);
        if (Object.keys(filteredGraph).length) {
          let graphData = convertTreeTod3GraphData(filteredGraph);
          setCurGraph(graphData);
        }
      } else {
        let filteredGraph = filterGraph(term);
        if (Object.keys(filteredGraph).length) {
          let graphData = convertTreeTod3GraphData(filteredGraph);
          setCurGraph(graphData);
        }
      }
      // console.log({hi: filterGraph(term)})/
      // console.log({curGraph})
    }
  };
  return (
    <div className="flud-container m-3">
      <div className="row">
        <div className="col-12">
          <WhiteCard title="گراف مفاهیم" className="full-height">
            <div className="concept-graph-view">
              <SearchButton
                top="50px"
                title="Exact Match"
                onSearchConcept={filterGraphContent}
              />
              <SearchButton
                top="150px"
                title="Partial Match"
                onSearchConcept={(term) => filterGraphContent(term, true)}
              />
              <RadioFloatingBox onChangeOption={fetchConceptTreeData} />
              {loading ? (
                <div className="floating-loading">
                  <LoadingIndicator />
                </div>
              ) : (
                graphData.nodes && (
                  <ConceptMap
                    onNodeClicked={onNodeClicked}
                    data={curGraph}
                    nodes={curGraph.nodes.length}
                  />
                )
              )}
            </div>
          </WhiteCard>
        </div>
      </div>
    </div>
  );
};

export default ConceptGraphView;
