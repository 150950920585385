import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import Axios, { REACT_APP_BACKEND_URL } from "../services/axios.custom";
import { COLOR_LIST, NAMED_COLORS } from "../data_utils/colors";
import StaticGraph from "../components/utils/StaticGraph";
import CustomChip from "../components/utils/SuccessChip";
import MathText from "../components/utils/MathText";
import LoadingIndicator from "../components/utils/LoadingIndicator";
import ApexLineChart from "../components/charts/ApexLineChart";
import WorkStatsCard from "../components/works/WorkStatsCard";
import { englishNumberToPersian } from "../data_utils/language_converter";
import MateriaTable from "../components/utils/MaterialTable";
import WhiteCard from "../components/utils/WhiteCard";
import SimilarWorksBySemanticAbstract from "../components/works/SimilarWorksBySemanticAbstract";

const BASE_URL = "https://openalex.org/";
const SingleWorkView = (props) => {
  const [work, setWork] = useState({});
  // const [similarWorks, setSimilarWorks] = useState([]);
  const params = useParams();
  // console.log(params);
  useEffect(() => {
    const fetchWork = async () => {
      try {
        const { data } = await Axios.get(`/api/works/${params.id}`);
        setWork(data);
      } catch (e) {
        console.log(e);
      }
    };
    fetchWork();
  }, [params.id]);
  const renderStats = () => {
    let stats = [
      {
        display_name: "تعداد ارجاعات",
        value: work.cited_by_count,
        color: NAMED_COLORS.LIGHT_YELLOW,
      },
      {
        display_name: "نوع",
        value: work.type === "article" ? "مقاله" : "کتاب",
        color: NAMED_COLORS.LIGHT_PURPLE,
      },
      {
        display_name: "دسترسی",
        value: work.open_access.is_oa ? "رایگان" : "پولی",
        color: NAMED_COLORS.LIGHT_GREEN,
      },
      {
        display_name: "سال انتشار",
        value: work.publication_year,
        color: NAMED_COLORS.LIGHT_PEACH,
      },
      {
        display_name: "تعداد ارجاع دهنده",
        value: work.referenced_works_count,
        color: NAMED_COLORS.LIGHT_LAVENDER,
      },
      {
        display_name: "زبان",
        value: work.language == "en" ? "انگلیسی" : work.language,
        color: NAMED_COLORS.LIGHT_MINT,
      },
    ];
    // const stats = [
    //   {
    //     name: "h_index",
    //     display_name: "H Index",
    //     value: author.summary_stats.h_index,
    //     color: NAMED_COLORS.LIGHT_PINK,
    //   },
    //   {
    //     name: "i10_index",
    //     display_name: "I10 Index",
    //     value: author.summary_stats.i10_index,
    //     color: NAMED_COLORS.LIGHT_GREEN,
    //   },
    //   {
    //     name: "total_citations",
    //     display_name: "کل ارجاعات",
    //     value: author.cited_by_count,
    //     color: NAMED_COLORS.LIGHT_BLUE,
    //   },
    //   {
    //     name: "Count of Works",
    //     display_name: "تعداد مقالات",
    //     value: author.works_count,
    //     color: NAMED_COLORS.LIGHT_PURPLE,
    //   },
    // ];
    stats.forEach((stat) => {
      stat.value = englishNumberToPersian(stat.value);
    });
    return <WorkStatsCard stats={stats} />;
  };

  const renderWork = () => {
    if (Object.keys(work).length === 0) {
      return <LoadingIndicator />;
    }
    // console.log({ work });
    let stats = [
      {
        display_name: "تعداد ارجاعات",
        value: work.cited_by_count,
        color: NAMED_COLORS.LIGHT_YELLOW,
      },
      {
        display_name: "نوع",
        value: work.type === "article" ? "مقاله" : "کتاب",
        color: NAMED_COLORS.LIGHT_PURPLE,
      },
      {
        display_name: "دسترسی",
        value: work.open_access.is_oa ? "رایگان" : "پولی",
        color: NAMED_COLORS.LIGHT_GREEN,
      },
      {
        display_name: "سال انتشار",
        value: work.publication_year,
        color: NAMED_COLORS.LIGHT_PEACH,
      },
      {
        display_name: "تعداد ارجاع دهنده",
        value: work.referenced_works_count,
        color: NAMED_COLORS.LIGHT_LAVENDER,
      },
      {
        display_name: "زبان",
        value: work.language == "en" ? "انگلیسی" : work.language,
        color: NAMED_COLORS.LIGHT_MINT,
      },
    ];
    let keywords = work.keywords.map((keyword, index) => ({
      display_name: keyword.display_name,
      value: keyword.score,
      color: COLOR_LIST[index % COLOR_LIST.length],
    }));
    const chartData = work.counts_by_year;
    chartData.sort((a, b) => (a.year > b.year ? 1 : -1));
    let authorsUniqueCountries = new Set();
    for (let authorship of work.authorships) {
      if (authorship.countries[0]) {
        authorsUniqueCountries.add(authorship.countries[0]);
      }
    }
    authorsUniqueCountries = Array.from(authorsUniqueCountries);
    const concepts = work.concepts;
    concepts.forEach((concept) => {
      concept.score *= 100;
    });
    // let chartData = [];
    // if (author.counts_by_year) {
    //     chartData = author.counts_by_year;
    //     chartData.sort((a, b) => (a.year > b.year ? 1 : -1));
    // }
    let xData = chartData.map((x) => x.year);
    // let worksCount = chartData.map((x) => x.works_count);
    let citations = chartData.map((x) => x.cited_by_count);
    return (
      <>
        <div className="work-title-section">
          <div className="white_card rounded-bg m-2 p-3">
            <div className="d-flex justify-content-between align-items-center">
              <div className="main-title">
                <h3
                  dangerouslySetInnerHTML={{
                    __html: work.display_name,
                  }}
                  className="text-title"
                ></h3>
              </div>
              <div className="flex-no-wrap d-flex">
                {authorsUniqueCountries.slice(0, 5).map((authorship) => (
                  <img
                    key={authorship}
                    className="inline-flag me-1"
                    style={{
                      minHeight: "20px",
                      minWidth: "30px",
                    }}
                    src={`${REACT_APP_BACKEND_URL}/static/flags/${authorship}.svg`}
                    alt="country flag"
                  />
                ))}
              </div>
            </div>
            <div className="text-subtitle">
              {work.authorships &&
                work.authorships.slice(0, 10).map((author) => (
                  <NavLink
                    key={author.author.id}
                    to={`/authors/${author.author.id.split("lex.org/")[1]}`}
                    className={
                      "interlink m-1 border rounded p-1 d-inline-block" +
                      (author.author_position === "first" ? " mui-success" : "")
                    }
                  >
                    {author.author.display_name}{" "}
                    {author.institutions[0] &&
                      author.institutions.map((institution) => (
                        <span>({institution.display_name})</span>
                      ))}
                  </NavLink>
                ))}
            </div>
          </div>

          {/* {authorsUniqueCountries.map((authorship) => (
                            <img
                                className="inline-flag "
                                src={`http://localhost:5000/static/flags/${authorship}.svg`}
                                alt="country flag"
                            />
                        ))} */}
          {/* </div> */}
        </div>
        <div className="d-flex">
          <div className="d-flex flex-grow-1 flex-column">
            <div className="white_card rounded-bg me-2 m-2">
              <div className="white_card_header rtl">
                <div className="main-title">
                  <h3 className="m-0">تعداد ارجاعات در طول زمان</h3>
                  <span>از سال ۲۰۱۲ الی ۲۰۲۴</span>
                </div>
              </div>
              <div className="author-charts" style={{ height: "200px" }}>
                <ApexLineChart
                  xData={xData}
                  yData={citations}
                  name={"تعداد ارجاعات"}
                  color={"orangered"}
                />
              </div>
            </div>
            <WhiteCard title="چکیده">
              {/* <div className="white_card rounded-bg m-2 p-4 work-abstract-section mt-3">
              <h2 className="text-uppercase font-italic rtl">چکیده</h2> */}
              <>
                <MathText>{work.abstract}</MathText>
                <hr />
                {keywords.map((keyword) => (
                  <NavLink
                    to={`/keywords/${keyword.display_name
                      .toLowerCase()
                      .split(" ")
                      .join("-")}`}
                  >
                    <CustomChip
                      key={keyword.display_name}
                      className="me-2"
                      color="info"
                      label={keyword.display_name}
                    />
                  </NavLink>
                ))}
              </>
            </WhiteCard>
            {/* </div> */}
          </div>
          <div
            style={{ minWidth: "450px", maxWidth: "50%" }}
            className="m-2 flex-grow-1 min-width-40"
          >
            {renderStats()}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <SimilarWorksBySemanticAbstract workId={work.id} />
          </div>
        </div>
        <div className="white_card my-3">
          <div className="white_card_header rtl">
            <div className="main-title">
              <h3 className="m-0">فهرست پژوهش های ارجاع داده</h3>
              <span>مرتب شده بر اساس تعداد ارجاعات</span>
            </div>
          </div>
          <div className="white_card_body">
            <MateriaTable
              fields={["display_name", "cited_by_count", "publication_year"]}
              fetch_url={`/api/works?filter=related_to:${params.id}&select=display_name,cited_by_count,publication_year,id`}
              redirect_url={"/works"}
              titles={["عنوان اثر", "تعداد ارجاعات", "سال انتشار"]}
            />
          </div>
        </div>
        {/* <div className="mt-3 border rounded">
          <GenericTable
            fetch_url={`/api/works?filter=related_to:${params.id}&select=display_name,cited_by_count,publication_year`}
            fields={["display_name", "cited_by_count", "publication_year"]}
            titles={["عنوان", "ارجاعات", "سال انتشار"]}
            redirect_url={"/works"}
          />
        </div> */}
        <div className="white_card">
          <div className="white_card_header rtl">
            <div className="main-title">
              <h3 className="m-0">گراف ارتباطی</h3>
              <span>رابطه میان تمامی مفاهیم اشاره شده در پژوهش</span>
            </div>
          </div>
          <StaticGraph concepts={concepts} />
        </div>
      </>
    );
  };
  return (
    <div className="container-fluid mt-2">
      <div className="card-body">{renderWork()}</div>
    </div>
  );
};

export default SingleWorkView;
