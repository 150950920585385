import React, { useState } from "react";

const KeywordsListFilters = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const onButtonClicked = () => {
    onSearch(searchTerm);
  };

  return (
    <div className="author-filters my-3">
      <div className=" mb-3 d-flex align-items-center justify-content-center">
        <button
          className="btn btn-outline-success"
          type="button"
          onClick={onButtonClicked}
        >
          جستجو
        </button>
        <input
          type="text"
          className="form-control search-input mx-2 rtl"
          placeholder="کلید واژه"
          aria-label="کلید واژه"
          aria-describedby="basic-addon2"
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        {/* <div className="input-group-append"></div> */}
      </div>
    </div>
  );
};

export default KeywordsListFilters;
