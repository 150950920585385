import React from "react";

const WhiteCard = ({
  className = "",
  title,
  subtitle,
  children,
  isRtl = true,
}) => {
  return (
    <div className={`white_card my-3 ${className}`}>
      {(title || subtitle) && (
        <div className={`white_card_header ${isRtl ? "rtl" : "ltr"}`}>
          <div className="main-title">
            <h3 className="m-0">{title}</h3>
            <span>{subtitle}</span>
          </div>
        </div>
      )}
      {children && <div className="white_card_body">{children}</div>}
    </div>
  );
};

export default WhiteCard;
