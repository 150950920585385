import React, { useState } from "react";

const WorksListFilters = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const onButtonClicked = () => {
    onSearch(searchTerm);
  };

  return (
    <div className="author-filters my-3">
      <div className=" mb-3 d-flex align-items-center justify-content-center rtl">
        <input
          type="text"
          className="form-control search-input mx-2"
          placeholder="عنوان پژوهش"
          aria-label="عنوان پژوهش"
          aria-describedby="basic-addon2"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button
          className="btn btn-outline-success"
          type="button"
          onClick={onButtonClicked}
        >
          جستجو
        </button>
        {/* <div className="input-group-append"></div> */}
      </div>
    </div>
  );
};

export default WorksListFilters;
