import Axios from "axios";

// console.log('BACKEND API ROUTE', process.env.REACT_APP_BACKEND_URL)
// export const REACT_APP_BACKEND_URL = "http://localhost:5000";
export const REACT_APP_BACKEND_URL = 'http://65.109.199.184:5000' || process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";

// export const REACT_APP_BACKEND_URL = "http://188.121.107.133:5000";
// export const REACT_APP_BACKEND_URL = "";
// Axios.defaults.baseURL = REACT_APP_BACKEND_URL;
Axios.defaults.baseURL = REACT_APP_BACKEND_URL; // + "/api";
Axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers.Authorization = `${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default Axios;
