import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import MateriaTable from "../components/utils/MaterialTable";
import WhiteCard from "../components/utils/WhiteCard";

const SingleKeywordView = () => {
  const params = useParams();
  //   useEffect(() => {
  //     const fetchCountry = async () => {
  //       try {
  //         const { data } = await Axios.get(`/api/countries/${params.id}`);
  //         // dispatch(setSingleAuthor(data));
  //         setCountry(data);
  //       } catch (e) {
  //         console.log(e);
  //       }
  //     };
  //     fetchCountry();
  //   }, []);
  let title = params.id.toUpperCase().split("-").join(" ");
  console.log({ param_id: params.id });
  return (
    <div>
      <div className="container-fluid my-2">
        <div className="row">
          <div className="col">
            <div className="ltr">
              <WhiteCard subtitle={title} title="کلید واژه"></WhiteCard>
            </div>
          </div>
          <div className="col-12">
            <WhiteCard
              title={`فهرست پژوهش های مرتبط با ${title}`}
              subtitle={"مرتب شده بر اساس تعداد ارجاعات"}
            >
              <MateriaTable
                fields={["display_name", "cited_by_count", "publication_year"]}
                fetch_url={`/api/works?filter=keywords.id:keywords/${params.id}&select=display_name,cited_by_count,publication_year,id&sort=cited_by_count:desc`}
                redirect_url={"/works"}
                titles={["نام پژوهشگر", "تعداد ارجاعات", "سال انتشار"]}
              />
            </WhiteCard>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleKeywordView;
