export const convertTreeTod3GraphData = (
    root,
    multiplier = 1000,
    max_size = 10000
) => {
    let graph = {
        links: [],
        nodes: [],
    };
    const fetchNodeAndDescendant = (node) => {
        // console.log({ node });
        let nodes = [
            {
                id: node.name,
                name: node.name,
                svg: node.svg,
                color: node.color,
                size: Math.min(node.size * multiplier, max_size),
                full_description: node.full_description,
            },
        ];
        if (!node.children) {
            return nodes;
        }
        for (let child of node.children) {
            nodes.push(...fetchNodeAndDescendant(child));
        }
        return nodes;
    };

    const fetchLinks = (node) => {
        let links = [];
        if (!node.children) {
            return links;
        }
        for (let child of node.children) {
            links.push({ source: node.name, target: child.name });
            links.push(...fetchLinks(child));
        }
        return links;
    };

    graph.nodes = fetchNodeAndDescendant(root);
    graph.links = fetchLinks(root);
    return graph;
};

export const separateGraph = (root) => {
    let graph = {};
    const dfs = (node) => {
        const nodeName = node.name;
        const children = node.children || [];
        graph[nodeName] = children.map((child) => child.name);
        children.map(dfs);
    };

    // const dfs = node => {
    //     const nodeName = node.name;
    //     const children = node.children || [];

    //     // if (nodeName in graph) {
    //     //     let newValues = children.map(child => child.name)

    //     //     if (JSON.stringify(newValues) !== JSON.stringify(graph[nodeName])) {
    //     //         console.log({nodeName, oldValues: graph[nodeName], newValues: children.map(child => child.name)})
    //     //     }
    //     // }

    //     let res = {
    //         [nodeName]: children.map(child => child.name),
    //     }
    //     for (let child of children) {
    //         res = {...res, ...dfs(child)}
    //     }
    //     // graph[nodeName] = children.map(child => child.name);
    //     // children.map(dfs);
    //     return res
    // }
    dfs(root);
    return graph;
};

export const graphNodeChildren = (graph) => {
    let res = {};
    for (let node of graph.nodes) {
        res[node.name] = [];
    }
    for (let link of graph.links) {
        res[link.source].push(link.target);
    }
    return res;
};

export const toggleKey = (key, curGraph, separateView, nodePropertyMapping) => {
    if (key === "PARENT") {
        return curGraph;
    }
    if (!(key in separateView)) {
        return;
    }
    let found = false;

    for (let link of curGraph.links) {
        if (link.source === key) {
            found = true;
            break;
        }
    }
    let nodes, links;
    console.log(separateView, separateView[key]);
    if (!found) {
        links = [
            ...curGraph.links,
            ...separateView[key].map((target) => ({ source: key, target })),
        ];
        nodes = [
            ...curGraph.nodes,
            ...separateView[key].map((name) => nodePropertyMapping[name]),
        ];
        // links = [...curGraph.links, ...separateView[key]];
        // nodes = [...curGraph.nodes, ...separateView[key]];
    } else {
        let g = {};
        let indegree = {};
        for (let link of curGraph.links) {
            try {
                g[link.source].push(link.target);
            } catch {
                g[link.source] = [link.target];
            }
            indegree[link.target] = !indegree[link.target]
                ? 1
                : indegree[link.target] + 1;
        }
        console.log(indegree);
        const allDescendantNodes = (node) => {
            if (node !== key && indegree[node] > 1) {
                return [];
            }
            if (!(node in g)) {
                return [node];
            }
            let res = [];
            for (let neigh of g[node]) {
                res.push(...allDescendantNodes(neigh));
            }
            res.push(node);
            return res;
        };
        let descendants = allDescendantNodes(key);
        const descendant_set = new Set(descendants);

        links = curGraph.links.filter(
            (link) => !descendant_set.has(link.source)
        );
        let neededNodes = new Set();
        for (let link of links) {
            neededNodes.add(link.source);
            neededNodes.add(link.target);
        }
        nodes = curGraph.nodes.filter((node) => neededNodes.has(node.name));
        console.log({ links, nodes });
    }

    return {
        nodes,
        links,
    };
};

export const selectGraphCenter = (graph, nodePropertyMapping) => {
    let links = graph.links.filter((link) => link.source === "PARENT");
    let nodes = links.map((link) => nodePropertyMapping[link.target]);
    nodes.push(nodePropertyMapping[links[0].source]);
    return {
        nodes,
        // nodes: nodes.map(d => ({...d, color: '#3300aa'})),
        links,
    };
};
