import React, { useEffect, useState } from "react";
import WhiteCard from "../components/utils/WhiteCard";
import WorksListFilters from "../components/works/WorksListFilters";
import WorkSemanticSearchFilters from "../components/works/WorkSemanticSearchFilters";
import Axios from "../services/axios.custom";
import SimilarWorksTable from "../components/works/SimilarWorksTable";
import StaticMaterialTable from "../components/utils/StaticMaterialTable";

const SemanticSearchWorksView = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState([]);
  useEffect(() => {
    if (!searchTerm.length) return;
    const fetchSimilarDocuments = async () => {
      try {
        const { data } = await Axios.post("/semantic/search", {
          query: searchTerm,
          top_n: 10,
        });
        // console.log({ data });
        setData(
          data.map((record) => {
            const recordId = record.id.split("/");

            return {
              ...record,
              workId: recordId[recordId.length - 1],
            };
          })
        );
      } catch (e) {
        console.log("error fetching similar documents", e);
      }
    };
    fetchSimilarDocuments();
  }, [searchTerm]);
  console.log({ data });
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <WhiteCard title="اعمال فیلتر">
            <WorkSemanticSearchFilters onSearch={setSearchTerm} />
          </WhiteCard>
        </div>
        <div className="col-12">
          {data.length ? (
            <WhiteCard
              title="مقالات مشابه"
              subtitle="مقالات مشابه بر اساس قرابت مفهومی پژوهش ها"
            >
              {/* <SimilarWorksTable data={data} /> */}
              <StaticMaterialTable
                column_fields={[
                  "display_name",
                  "publication_year",
                  "cited_by_count",
                ]}
                data={data}
                column_names={["عنوان پژوهش", "سال انتشار", "تعداد ارجاعات"]}
                expand_key={"abstract"}
                redirect_field="display_name"
                id_field={"workId"}
                perPage={5}
                redirect_url={"/works"}
              />
            </WhiteCard>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default SemanticSearchWorksView;
