import React from "react";
import Chart from "react-apexcharts";

const ApexTreeMapChart = ({ labels, values, height }) => {
  // Prepare the series data based on labels and values
  const series = [
    {
      data: labels.map((label, index) => ({
        x: label,
        y: values[index],
      })),
    },
  ];

  const options = {
    chart: {
      type: "treemap",
    },
    title: {
      text: "",
      align: "center",
    },
    plotOptions: {
      treemap: {
        distributed: true,
        enableShades: true,
      },
    },
    legend: {
      show: false,
    },
  };

  return (
    <div className="tree-map">
      <Chart
        options={options}
        series={series}
        type="treemap"
        height={height || "700"}
      />
    </div>
  );
};

export default ApexTreeMapChart;
