import React, { useEffect, useState } from "react";
import StaticMaterialTable from "../utils/StaticMaterialTable";
import WhiteCard from "../utils/WhiteCard";
import Axios from "../../services/axios.custom";
import { CountryCodeToPersianName } from "../../data_utils/country_continent";

const CoauthoredCountries = ({ countryId }) => {
  const [coauthoredCountries, setCoauthoredCountries] = useState([]);
  useEffect(() => {
    const fetchCoauthoredCountries = async () => {
      try {
        const { data } = await Axios.get(
          `/v2/api/country_most_common_coauthorship/${countryId}`
        );
        data.forEach((country) => {
          country["persian_name"] =
            CountryCodeToPersianName[country["country"]];
        });
        setCoauthoredCountries(data);
      } catch (e) {
        console.log("failed fetching country coauthor");
      }
    };
    fetchCoauthoredCountries();
  }, []);
  return (
    <WhiteCard
      title="کشورهایی با بیشترین پژوهش مشترک"
      subtitle="مرتب شده بر اساس تعداد پژوهش مشترک"
    >
      <StaticMaterialTable
        data={coauthoredCountries}
        column_fields={["persian_name", "works_count"]}
        column_names={["کشور", "تعداد مقالات مشترک"]}
        id_field={"country"}
        redirect_field={"persian_name"}
        redirect_url={"/countries"}
        expand_key={null}
      />
    </WhiteCard>
  );
};

export default CoauthoredCountries;
