// store.js
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

const initialAuthorsListState = {
    authors: [],
    total_authors: 0,
    fetch_url: "/api/authors?per_page=10",
    page: 1,
};
const authorsListReducer = (state = initialAuthorsListState, action) => {
    switch (action.type) {
        case "AUTHOR_LIST_FETCHED":
            return {
                ...state,
                authors: action.payload.authors,
                total_authors: action.payload.count,
            };
        case "SET_AUTHORS_FETCH_URL":
            return {
                ...state,
                fetch_url: action.payload,
            };
        case "SET_AUTHORS_LIST_PAGE":
            return {
                ...state,
                page: action.payload,
            };
        default:
            return state;
    }
};
const initialSingleAuthorState = {
    author: {},
};
const singleAuthorReducer = (state = initialSingleAuthorState, action) => {
    switch (action.type) {
        case "SET_SINGLE_AUTHOR":
            return {
                ...state,
                author: action.payload,
            };
        default:
            return state;
    }
};

const initialLoginState = {
    isLoggedIn: localStorage.getItem("isLoggedIn") === "true",
    token: localStorage.getItem("token") || "",
    username: localStorage.getItem("username") || "",
};
const authReducer = (state = initialLoginState, action) => {
    switch (action.type) {
        case "LOGIN":
            localStorage.setItem("isLoggedIn", "true");
            localStorage.setItem("token", action.payload.token);
            localStorage.setItem("username", action.payload.username);
            return {
                ...state,
                isLoggedIn: true,
                ...action.payload,
            };
        case "LOGOUT":
            localStorage.setItem("isLoggedIn", "false");
            localStorage.removeItem("token");
            localStorage.removeItem("username");
            return {
                ...state,
                isLoggedIn: false,
                token: "",
                username: "",
            };
        default:
            return state;
    }
};

const appReducer = combineReducers({
    auth: authReducer,
    authorsList: authorsListReducer,
    author: singleAuthorReducer,
});

const rootStore = (state, action) => {
    if (action.type === "LOGOUT") {
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};

// Create the Redux store
const store = configureStore({
    reducer: rootStore,
    // middleware: thunk,
});

export default store;
