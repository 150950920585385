import { NavLink } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import { GoPeople } from "react-icons/go";
import { PiStack } from "react-icons/pi";
import { LiaUniversitySolid } from "react-icons/lia";
import { PiMapPin } from "react-icons/pi";
import { MdAttachMoney } from "react-icons/md";
import { GoRepoPush } from "react-icons/go";
import { LuPrinter } from "react-icons/lu";
import { HiHashtag } from "react-icons/hi2";
import { PiGraphThin } from "react-icons/pi";

import "./Navbar.css";

const Navbar = () => {
  return (
    <div className="navbar d-flex flex-column flex-nowrap rtl pt-5 justify-content-start">
      <div className="full-width mb-4 text-center">
        <img
          className="app-logo"
          src="/knowledge_mind_prev_ui.png"
          alt="app icon"
        />
      </div>
      <NavLink to={"/semantic-search"} className="link full-width py-3">
        <CiSearch className="me-3" />
        <span className="pe-3">جستجوی مفهومی مقالات</span>
      </NavLink>

      <NavLink to={"/authors"} className="link full-width py-3">
        <GoPeople className="me-3" />
        <span className="pe-3">فهرست پژوهش‌گران</span>
      </NavLink>
      <NavLink to={"/works"} className="link full-width py-3">
        <PiStack className="me-3" />
        <span className="pe-3">فهرست تحقیقات</span>
      </NavLink>
      <NavLink to={"/institutions"} className="link full-width py-3">
        <LiaUniversitySolid className="me-3" />
        <span className="pe-3">مراکز تحقیقاتی</span>
      </NavLink>
      <NavLink to={"/countries"} className="link full-width py-3">
        <PiMapPin className="me-3" />
        <span className="pe-3">فهرست کشورها</span>
      </NavLink>
      <NavLink to={"/funders"} className="link full-width py-3">
        <MdAttachMoney className="me-3" />
        <span className="pe-3">فهرست تامین کنندگان مالی</span>
      </NavLink>
      <NavLink to={"/sources"} className="link full-width py-3">
        <GoRepoPush className="me-3" />
        <span className="pe-3">فهرست منابع</span>
      </NavLink>
      <NavLink to={"/publishers"} className="link full-width py-3">
        <LuPrinter className="me-3" />
        <span className="pe-3">فهرست انتشارات</span>
      </NavLink>
      <NavLink to={"/keywords"} className="link full-width py-3">
        <HiHashtag className="me-3" />
        <span className="pe-3">فهرست کلید واژه ها</span>
      </NavLink>
      <NavLink to={"/concepts"} className="link full-width py-3">
        <PiGraphThin className="me-3" />
        <span className="pe-3">گراف مفاهیم</span>
      </NavLink>
    </div>
  );
};

export default Navbar;
