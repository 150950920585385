import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Axios from "../../services/axios.custom";
import { NavLink } from "react-router-dom";
import WhiteCard from "../utils/WhiteCard";
import { CountryCodeToPersianName } from "../../data_utils/country_continent";
import StaticMaterialTable from "../utils/StaticMaterialTable";

const CoAuthors = ({ authorId }) => {
  const [coAuthors, setCoAuthors] = useState([]);
  const titles = ["نام نویسنده", "تعداد پژوهش های مشترک", "ملیت"];
  const fields = ["display_name", "count", "country_code"];
  let redirect_url = "/authors";
  useEffect(() => {
    const fetchAuthorCoAuthors = async () => {
      try {
        const { data } = await Axios.get(`/v2/api/coauthors/${authorId}`);
        setCoAuthors(
          data.map((x) => {
            let splits = x.id.split("/");
            let authorId = splits[splits.length - 1];
            return {
              ...x,
              country_code: CountryCodeToPersianName[x["country_code"]],
              authorId,
            };
          })
        );
      } catch (e) {
        console.log("error fetching coauthors");
      }
    };
    fetchAuthorCoAuthors();
  }, []);
  const onClick = () => {};
  console.log({ coAuthors });
  return (
    <WhiteCard
      title={"همکاران نویسنده"}
      subtitle="نویسندگانی که بیشترین تعداد مقاله مشترک با نویسنده دارند"
    >
      <StaticMaterialTable
        column_fields={fields}
        data={coAuthors}
        column_names={titles}
        id_field={"authorId"}
        redirect_field={fields[0]}
        redirect_url={redirect_url}
        perPage={5}
      />
      {/* <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {titles.map((title) => (
                <TableCell>{title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {coAuthors.map((row) => {
              const splits = row.id.split("/");
              const authorId = splits[splits.length - 1];
              return (
                <TableRow key={row.id}>
                  <TableCell key={row[fields[0]]}>
                    <NavLink
                      onClick={() => onClick(row)}
                      className="clickable-link"
                      dangerouslySetInnerHTML={{
                        __html: row[fields[0]],
                      }}
                      to={`${redirect_url}/${authorId}`}
                    ></NavLink>
                  </TableCell>
                  {fields.slice(1).map((field) => {
                    return (
                      <TableCell
                        dangerouslySetInnerHTML={{
                          __html: row[field],
                        }}
                        key={row[field]}
                      ></TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer> */}
    </WhiteCard>
  );
};

export default CoAuthors;
