import { useNavigate } from "react-router-dom";
import "./Header.css";
import { useDispatch } from "react-redux";
import { logout } from "../store/actions/auth_actions";

const Header = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onLogoutClicked = (e) => {
        e.preventDefault();
        dispatch(logout());
        navigate("/login");
    };
    return (
        <div className="header">
            <span className="header-title">داشبورد فناوری و دانش</span>
            <a onClick={onLogoutClicked} className="logout ms-3">
                خروج
            </a>
        </div>
    );
};

export default Header;
