import React from "react";
import Chart from "react-apexcharts";

const ApexPieChart = ({ labels, values, height }) => {
  console.log({ labels, values });
  const options = {
    chart: {
      //   width: 380,
      type: "pie",
    },
    labels: labels,
    responsive: [
      {
        // breakpoint: 480,
        options: {
          // chart: {
          //   width: 300,
          // },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <div>
      <Chart
        options={options}
        series={values}
        type="pie"
        height={height || "400"}
      ></Chart>
    </div>
  );
};

export default ApexPieChart;
