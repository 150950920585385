import { ALL_PER_PAGE, AUTHORS_PER_PAGE } from "./constants";

export const make_query = ({
    base,
    per_page = AUTHORS_PER_PAGE,
    page = 1,
    search = "",
}) => {
    return `/api/${base}?per-page=${per_page}&page=${page}&search=${search}`;
};
export class QueryBuilder {
    constructor(base) {
        this.base = base;
        this.url = `/api/${base}?`;
        this._initialize_params();
    }
    _initialize_params() {
        this.search = null;
        this.per_page = ALL_PER_PAGE;
        this.page = 1;
        this.fields = [];
    }

    reset() {
        this._initialize_params();
        return this.make_url();
    }

    make_url() {
        let appendage = "";
        if (this.search) {
            appendage += `search=${this.search}&`;
        }
        if (this.per_page) {
            appendage += `per-page=${this.per_page}&`;
        }
        if (this.page) {
            appendage += `page=${this.page}&`;
        }
        if (this.fields.length) {
            appendage += `select=${this.fields.join(",")}`;
        }

        return this.url + appendage;
    }
    add_search(term) {
        this.page = 1;
        this.search = term;
        return this.make_url();
    }
    set_page(page) {
        this.page = page;
        return this.make_url();
    }
    set_per_page(per_page) {
        this.per_page = per_page;
        return this.make_url();
    }
    set_fields(fields) {
        this.fields = fields;
    }
}
