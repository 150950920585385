import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Axios from "../services/axios.custom";
import {
  setAuthorList,
  setAuthorsListFetchUrl,
  setAuthorsListPage,
} from "../store/actions/authors_actions";
import AuthorsList from "../components/authors-list-view/AuthorsList";
import AuthorsListFilters from "../components/authors-list-view/AuthorsListFilters";
import Pagination from "@mui/material/Pagination";
import { QueryBuilder, make_query } from "../data_utils/query_builder";
import { AUTHORS_PER_PAGE } from "../data_utils/constants";
import MateriaTable from "../components/utils/MaterialTable";
import {
  BASE_AUTHORS_API_URL,
  BASE_WORKS_API_URL,
} from "../data_utils/base_api_urls";
import WhiteCard from "../components/utils/WhiteCard";
import ApexPieChart from "../components/charts/ApexPieChart";
import { convertCountryCodeToContinent } from "../data_utils/country_continent";

const queryBuilder = new QueryBuilder("authors");

const AuthorsView = () => {
  const [search, setSearch] = useState("");
  const [url, setUrl] = useState(BASE_AUTHORS_API_URL);
  const [countryGroups, setCountryGroups] = useState([]);
  const [continentGroups, setContinentGroups] = useState({
    values: [],
    labels: [],
  });

  useEffect(() => {
    const fetchTypeGroups = async () => {
      try {
        // const { data } = await Axios.get(
        //   `${url}&group_by=last_known_institutions.country_code`
        // );
        const { data } = await Axios.post(`/v2/api/group_authors_by_country`, {
          search,
        });
        setCountryGroups(data);
        const byCountry = data;
        let continentLabels = [];
        let continentValues = [];
        let continentCount = {};
        for (let country of byCountry) {
          const cont = convertCountryCodeToContinent(country.key);
          if (cont in continentCount) {
            continentCount[cont] += country.doc_count;
          } else {
            continentCount[cont] = country.doc_count;
          }
        }
        for (let cont in continentCount) {
          continentLabels.push(cont);
          continentValues.push(continentCount[cont]);
        }
        setContinentGroups({
          labels: continentLabels,
          values: continentValues,
        });
      } catch (e) {
        console.log("error fetching group by for works");
      }
    };
    fetchTypeGroups();
  }, [url]);

  useEffect(() => {
    setUrl(`${BASE_AUTHORS_API_URL}search=${search}`);
  }, [search]);
  //   const { authors, total_authors, fetch_url, page } = useSelector(
  //     (state) => state.authorsList
  //   );
  //   const dispatch = useDispatch();

  //   const fetchAuthors = async (url) => {
  //     try {
  //       const { data } = await Axios.get(url);
  //       let results = data.results;
  //       for (let res of results) {
  //         res.counts_by_year.sort((a, b) => (a.year > b.year ? 1 : -1));
  //       }
  //       dispatch(
  //         setAuthorList({
  //           authors: results,
  //           count: data.meta.count,
  //         })
  //       );
  //     } catch {
  //       console.log("error occured.");
  //     }
  //   };
  //   useEffect(() => {
  //     fetchAuthors(fetch_url);
  //   }, [fetch_url]);
  //   const searchAuthors = (text) => {
  //     dispatch(setAuthorsListPage(1));
  //     dispatch(setAuthorsListFetchUrl(queryBuilder.add_search(text)));
  //   };
  //   const onPageChanged = (e, page) => {
  //     dispatch(setAuthorsListPage(page));
  //     dispatch(setAuthorsListFetchUrl(queryBuilder.set_page(page)));
  //   };

  return (
    <div className="container-fluid mt-2">
      <div className="row">
        <div className="col-12">
          <AuthorsListFilters onSearch={setSearch} />
          <div className="row">
            <div className="col-4">
              <WhiteCard
                title={"تنوع پژوهشگران"}
                subtitle={"گروه بندی پژوهشگران بر مبنای ملیت"}
              >
                {countryGroups.length ? (
                  <ApexPieChart
                    labels={countryGroups.map((group) => group.key)}
                    values={countryGroups.map((group) => group.doc_count)}
                  />
                ) : (
                  <div className="no-data-banner">
                    متاسفانه اطلاعاتی برای نمایش وجود ندارد.
                  </div>
                )}
              </WhiteCard>
              <WhiteCard
                title={"تنوع پژوهشگران"}
                subtitle={"گروه بندی پژوهشگران بر مبنای قاره"}
              >
                {continentGroups.labels.length ? (
                  <ApexPieChart
                    labels={continentGroups.labels}
                    values={continentGroups.values}
                  />
                ) : (
                  <div className="no-data-banner">
                    متاسفانه اطلاعاتی برای نمایش وجود ندارد.
                  </div>
                )}
              </WhiteCard>
            </div>
            <div className="col-8">
              {/* <div className="card shadow mt-2">
                <div className="card-body m-3"> */}
              <WhiteCard title={"جدول پژوهشگران"}>
                <MateriaTable
                  fetch_url={url}
                  fields={[
                    "display_name",
                    "cited_by_count",
                    "summary_stats.h_index",
                    "summary_stats.i10_index",
                  ]}
                  titles={["عنوان", "تعداد ارجاعات", "H Index", "ایندکس i10"]}
                  redirect_url={"/authors"}
                />
              </WhiteCard>
              {/* </div>
              </div> */}
            </div>
          </div>

          {/* <div className="card shadow">
            <div className="card-body m-3">
              <AuthorsList authors={authors} />
              <div className="d-flex justify-content-center">
                <Pagination
                  count={Math.ceil(total_authors / AUTHORS_PER_PAGE)}
                  showFirstButton
                  showLastButton
                  onChange={onPageChanged}
                  page={page}
                />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AuthorsView;
