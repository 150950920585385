import { useEffect, useState } from "react";
import "./Login.css";
import Axios from "../../services/axios.custom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/actions/auth_actions";
import { useNavigate } from "react-router-dom";
import { INITIAL_ROUTE_REDIRECT } from "../../data_utils/routes";
const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((store) => store.auth);
  useEffect(() => {
    if (isLoggedIn) {
      return navigate(INITIAL_ROUTE_REDIRECT);
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    try {
      // Send login request to the backend
      const { data } = await Axios.post("/login", {
        username,
        password,
      });

      const token = data.data.token;
      dispatch(login({ token, username }));
      localStorage.setItem("isLoggedIn", "true");
      localStorage.setItem("username", username);
      localStorage.setItem("token", token);
      navigate(INITIAL_ROUTE_REDIRECT);
      // Handle successful login
      // console.log(data.data.token); // Replace with your desired logic
    } catch (error) {
      // Handle login error
      setError(error.response.data.message || "Invalid Username or Password");
    }

    setIsLoading(false);
  };

  return (
    <div className="login-container rtl">
      <form className="login-form" onSubmit={handleLogin}>
        <h2 className="login-title">فرم ورود</h2>
        {error && <p className="error-message">{error}</p>}
        <div className="input-group">
          <label htmlFor="username">نام کاربری:</label>
          <input
            type="text"
            id="username"
            value={username}
            className="ltr"
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="input-group">
          <label htmlFor="password">گذرواژه:</label>
          <input
            type="password"
            id="password"
            className="ltr"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="login-button" disabled={isLoading}>
          {isLoading ? "در حال ورود..." : "ورود"}
        </button>
      </form>
    </div>
  );
};

export default Login;
