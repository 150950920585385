import React, { useState } from "react";
import "./RadioFloatingBox.css";

const RadioFloatingBox = ({ onChangeOption }) => {
    const [selectedOption, setSelectedOption] = useState("growth");
    const onChange = (e) => {
        setSelectedOption(e.target.value);
        onChangeOption(e.target.value);
    };
    return (
        <div className="radio-floating-box">
            <div className="form-check">
                <input
                    onChange={onChange}
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    value="image"
                    checked={selectedOption === "image"}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                    Image View
                </label>
            </div>
            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    onChange={onChange}
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    value="growth"
                    checked={selectedOption === "growth"}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                    Growth View
                </label>
            </div>
        </div>
    );
};

export default RadioFloatingBox;
