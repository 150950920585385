import React, { useEffect, useState } from "react";
import StaticMaterialTable from "../utils/StaticMaterialTable";
import WhiteCard from "../utils/WhiteCard";
import Axios from "../../services/axios.custom";

const SimilarWorksBySemanticAbstract = ({ workId }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (!workId) return;
    const fetchSimilarDocuments = async () => {
      try {
        const { data } = await Axios.post("/semantic/find-similar-works", {
          work_id: workId,
          top_n: 10,
        });
        // console.log({ data });
        setData(
          data
            .filter((work) => work.id != workId)
            .map((record) => {
              const recordId = record.id.split("/");

              return {
                ...record,
                workId: recordId[recordId.length - 1],
              };
            })
        );
      } catch (e) {
        console.log("error fetching similar documents", e);
      }
    };
    fetchSimilarDocuments();
  }, [workId]);
  console.log({ data });
  return (
    <div>
      <WhiteCard title={"فهرست مقالات مشابه با مقاله فوق"}>
        <StaticMaterialTable
          column_fields={["display_name", "publication_year", "cited_by_count"]}
          data={data}
          column_names={["عنوان پژوهش", "سال انتشار", "تعداد ارجاعات"]}
          expand_key={"abstract"}
          redirect_field="display_name"
          id_field={"workId"}
          perPage={5}
          redirect_url={"/works"}
        />
      </WhiteCard>
    </div>
  );
};

export default SimilarWorksBySemanticAbstract;
