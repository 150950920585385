import React from "react";
import { Typography } from "@mui/material";

function MathText({ children }) {
    return (
        <Typography>
            {children.split("$").map((text, index) => {
                if (index % 2 === 0) {
                    return <span key={index}>{text}</span>;
                } else {
                    return (
                        <span key={index}>
                            <MathFormula formula={text} />
                        </span>
                    );
                }
            })}
        </Typography>
    );
}

function MathFormula({ formula }) {
    return (
        <span
            dangerouslySetInnerHTML={{
                __html: formula,
            }}
        />
    );
}

export default MathText;
