import React, { useEffect, useState } from "react";
import { Graph } from "react-d3-graph";
import "./ConceptGraph.css";

export default function ConceptMap({ data: graphData, onNodeClicked, nodes }) {
  const ref = React.useRef(null);

  const [updateKey, setUpdateKey] = useState(0);

  const config = {
    directed: true,
    // automaticRearrangeAfterDropNode: true,
    collapsible: true,
    // height: window.innerHeight,
    height: 700,
    highlightDegree: 2,
    highlightOpacity: 0.2,
    linkHighlightBehavior: true,
    maxZoom: 12,
    minZoom: 0.05,
    nodeHighlightBehavior: true, // comment this to reset nodes positions to work
    panAndZoom: false,
    staticGraph: false,
    width: window.innerWidth,
    d3: {
      alphaTarget: 0.05,
      gravity: -250,
      linkLength: 120,
      linkStrength: 2,
    },
    node: {
      color: "#d3d3d3",
      fontColor: "black",
      fontSize: 10,
      fontWeight: "normal",
      highlightColor: "red",
      highlightFontSize: 14,
      highlightFontWeight: "bold",
      highlightStrokeColor: "red",
      highlightStrokeWidth: 1.5,
      labelProperty: (n) => n.name, //(n.name ? `${n.id} - ${n.name}` : n.id),
      mouseCursor: "crosshair",
      opacity: 0.9,
      renderLabel: true,
      size: 200,
      strokeColor: "none",
      strokeWidth: 1.5,
      svg: "",
      symbolType: "circle",
      viewGenerator: null,
    },
    link: {
      color: "lightgray",
      highlightColor: "red",
      mouseCursor: "pointer",
      opacity: 1,
      semanticStrokeWidth: true,
      strokeWidth: 3,
      type: "STRAIGHT",
    },
  };

  // const resetNodesPositions = React.useCallback(() => {
  //     if (ref.current) {
  //         ref.current.resetNodesPositions();
  //     }
  // }, [ref, graphData.nodes.length]);

  useEffect(() => {
    if (ref.current) {
      // setTimeout(() => {
      ref.current.resetNodesPositions();
      // }, 1000)
    }
  }, [ref, graphData.nodes.length]);

  // useEffect(() => {
  //     async function initGraphData() {
  //         // const data = await mockPromiseGetData();
  //         const data = mockData;
  //         setData(data);
  //     }
  //     initGraphData();
  // }, []);

  // useEffect(() => {
  //     if (data.nodes.length) {
  //         setUpdateKey((k) => ++k);
  //     }
  // }, [data]);
  useEffect(() => {
    setUpdateKey((k) => ++k);
    // ref.current.resetNodesPositions();
  }, [nodes]);

  return (
    <div key={updateKey}>
      {/* <button onClick={resetNodesPositions}>Reset Nodes</button> */}
      <Graph
        id="test"
        key={updateKey}
        data={graphData}
        config={config}
        ref={ref}
        onClickNode={onNodeClicked}
      />
    </div>
  );
}
